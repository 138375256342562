import ListMenu, { type ListMenuItem } from '@rio-cloud/rio-uikit/ListMenu';
import { MouseEventHandler, useState } from 'react';
import { useAppDispatch } from '../../configuration/setup/hooks';
import { store } from '../../configuration/setup/store';
import { RouteDetails } from '../../features/fetchData/mappers/mapRoutes';
import {
    createRouteInfo,
    propagateBoundingBox,
    propagateRoute,
} from '../../features/livemonitorCommunication/propagateRoutesToParent';
import { selectedRouteChanged } from '../../store/route/routeSlice';

interface TourAlternativesProps {
    routes: RouteDetails[];
}

interface MenuItemsProps {
    activeItem: string;
    onItemClick: MouseEventHandler<HTMLSpanElement>;
    routes: RouteDetails[];
}

const menuItems = ({ activeItem, onItemClick, routes }: MenuItemsProps): ListMenuItem[] => {
    const listMenu: ListMenuItem[] = [];
    listMenu.push({ navItems: [] });

    routes.forEach((route, index) => {
        const routeMarkerLabel = createRouteInfo(route.summary!, store.getState());
        listMenu[0].navItems.push({
            key: route.id,
            item: (
                <span
                    data-key={index.toString()}
                    data-testid="menu-list-item"
                    className={activeItem === index.toString() ? 'text-medium active' : ''}
                    onClick={onItemClick}
                >
                    <span className="rioglyph rioglyph-route padding-right-10" aria-hidden="true" />
                    {routeMarkerLabel}
                </span>
            ),
        });
    });

    return listMenu;
};

export const TourAlternatives = ({ routes }: TourAlternativesProps) => {
    const dispatch = useAppDispatch();

    const [activeItem, setActiveItem] = useState('0');

    const onItemClick = (event: MouseEvent) => {
        const key = (event.target as HTMLSpanElement).getAttribute('data-key') as string;

        setActiveItem(key);
        dispatch(selectedRouteChanged(Number(key)));
        dispatch(propagateRoute);
        dispatch(propagateBoundingBox);
    };

    return (
        <div data-testid="tour-alternatives">
            <div className="list-group margin-left--10" data-testid="tour-alternatives-panel">
                <ListMenu groupClassName="padding-0" menuItems={menuItems({ activeItem, onItemClick, routes })} />
            </div>
        </div>
    );
};

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { STORE_BASE_PATH } from '../../common/constants';
import { routes } from '../../routes/Router';

export const getRouteFromStep = (step: NavigationStep) => routes[step];

export enum NavigationStep {
    SettingsStep = 0,
    StopsStep,
    SummaryStep,
}

export interface AppState {
    sessionExpiredAcknowledged: boolean;
    currentStep: NavigationStep;
    showSettingsStep: boolean;
    showStopsStep: boolean;
    showSummaryStep: boolean;
}

const initialState: AppState = {
    sessionExpiredAcknowledged: false,
    showSettingsStep: true,
    showStopsStep: false,
    showSummaryStep: false,
    currentStep: NavigationStep.SettingsStep,
};

export const appSlice = createSlice({
    name: STORE_BASE_PATH + 'app',
    initialState,
    reducers: {
        hideSessionExpiredDialog: (state) => {
            state.sessionExpiredAcknowledged = true;
        },
        updateStep: (state, action: PayloadAction<NavigationStep>) => {
            state.currentStep = action.payload;
            state.showSettingsStep = action.payload === NavigationStep.SettingsStep;
            state.showStopsStep = action.payload === NavigationStep.StopsStep;
            state.showSummaryStep = action.payload > NavigationStep.SettingsStep;
        },
    },
});

export const { hideSessionExpiredDialog, updateStep } = appSlice.actions;

export default appSlice.reducer;

import { http, HttpResponse } from 'msw';
import { API, BASE_URL } from '../../../src/common/constants';
import { generateFeatureTogglesMock } from '../data/featureTogglesMock';

const URL = `${BASE_URL}${API.endpoints.featureToggles}`;

export default http.get(URL, () => {
    const featureToggles = generateFeatureTogglesMock();
    return HttpResponse.json(featureToggles);
});

export const featureTogglesErrorResponse = http.get(URL, () => {
    return HttpResponse.json({ errorMessage: 'Mock feature toggles response error' }, { status: 400 });
});

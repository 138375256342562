export const supportedCurrencies = [
    'EUR',
    'BAM',
    'BGN',
    'BRL',
    'CHF',
    'CZK',
    'DKK',
    'GBP',
    'HUF',
    'HRK',
    'MKD',
    'NOK',
    'PLN',
    'RON',
];

import { ReactElement } from 'react';

interface DefaultPanelProps {
    panelHeader?: ReactElement;
    panelBody?: ReactElement;
    panelFooter?: ReactElement;
}

export const DefaultPanel = ({ panelHeader, panelBody, panelFooter }: DefaultPanelProps) => {
    return (
        <div data-testid="default-panel">
            <div className="panel panel-default margin-bottom-0">
                {panelHeader && (
                    <div className="panel-heading" data-testid="default-panel-header">
                        {panelHeader}
                    </div>
                )}
                {panelBody && (
                    <div className="panel-body bg-lightest" data-testid="default-panel-body">
                        {panelBody}
                    </div>
                )}
                {panelFooter && (
                    <div className="panel-footer" data-testid="default-panel-footer">
                        {panelFooter}
                    </div>
                )}
            </div>
        </div>
    );
};

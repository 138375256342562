/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import PropTypes from 'prop-types';
import { createContext, ReactElement, useContext, useMemo, useState } from 'react';

interface RouteWidgetProviderProps {
    children?: ReactElement;
}

interface RouteWidgetContextProps {
    vehicleAtributesExpnd: boolean;
    setVehicleAtributesExpnd: (value?: boolean) => void;
    departureDateTimeExpnd: boolean;
    setDepartureDateTimeExpnd: (value?: boolean) => void;
    restrictionsExpnd: boolean;
    setRestrictionsExpnd: (value?: boolean) => void;
    costsExpnd: boolean;
    setCostsExpnd: (value?: boolean) => void;
    stateOfChargeExpnd: boolean;
    setStateOfChargeExpand: (value?: boolean) => void;
    charginOptionsExpnd: boolean;
    setCCharginOptionsExpnd: (value?: boolean) => void;
}

const RouteWidgetContext = createContext<RouteWidgetContextProps | null>(null);

export const RouteWidgetProvider = ({ children }: RouteWidgetProviderProps) => {
    const [vehicleAtributesExpnd, setVehicleAtributesExpnd] = useState<boolean>(false);
    const [departureDateTimeExpnd, setDepartureDateTimeExpnd] = useState<boolean>(false);
    const [restrictionsExpnd, setRestrictionsExpnd] = useState<boolean>(false);
    const [costsExpnd, setCostsExpnd] = useState<boolean>(false);
    const [stateOfChargeExpnd, setStateOfChargeExpand] = useState<boolean>(false);
    const [charginOptionsExpnd, setCCharginOptionsExpnd] = useState<boolean>(false);

    const context = useMemo(
        () => ({
            vehicleAtributesExpnd,
            setVehicleAtributesExpnd,
            departureDateTimeExpnd,
            setDepartureDateTimeExpnd,
            restrictionsExpnd,
            setRestrictionsExpnd,
            costsExpnd,
            setCostsExpnd,
            stateOfChargeExpnd,
            setStateOfChargeExpand,
            charginOptionsExpnd,
            setCCharginOptionsExpnd,
        }),
        [
            vehicleAtributesExpnd,
            setVehicleAtributesExpnd,
            departureDateTimeExpnd,
            setDepartureDateTimeExpnd,
            restrictionsExpnd,
            setRestrictionsExpnd,
            costsExpnd,
            setCostsExpnd,
            stateOfChargeExpnd,
            setStateOfChargeExpand,
            charginOptionsExpnd,
            setCCharginOptionsExpnd,
        ]
    );

    return (
        <RouteWidgetContext.Provider value={context as RouteWidgetContextProps}>{children}</RouteWidgetContext.Provider>
    );
};

RouteWidgetProvider.propTypes = {
    children: PropTypes.shape({}).isRequired,
};

export default RouteWidgetContext;

export const useRouteWidgetContext = () => {
    const context = useContext(RouteWidgetContext);
    if (!context) {
        throw new Error('RouteWidgetContext must be used within RouteWidgetProvider');
    }
    return context;
};

export const generateVehicleProfileListMock = () => [
    {
        id: '02bb3f45-0b1d-4a11-8f20-05fde3b1b966',
        profileName: 'man-etgs-4x2-chassis-box-solo',
        engineType: 'BEV',
    },
    {
        id: '9fe9a4c7-1ea1-4515-931f-13984a27f9af',
        profileName: 'man-etgs-6x2-chassis-cooled-box',
        engineType: 'BEV',
    },
    {
        id: 'a0e1890c-69cf-4060-8ba0-c1bc4f966a8e',
        profileName: 'man-etgx-4x2-tractor-semitrailer',
        engineType: 'BEV',
    },
    {
        id: '92854298-8762-4e9e-9d21-e51889ffa08f',
        profileName: 'man-etgx-4x2-ultra-tractor-semitrailer',
        engineType: 'BEV',
    },
    {
        id: '8eb1bf34-9c01-4148-b325-5bd3e562d417',
        profileName: 'man-etgx-6x2-chassis-swap-body',
        engineType: 'BEV',
    },
    {
        id: '46a5cf52-e375-4c8b-96c7-b5b0cab77500',
        profileName: 'man-etgx-6x2-chassis-roll-off-tipper',
        engineType: 'BEV',
    },
];

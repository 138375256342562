import { config } from '../configuration/config';

export const BASE_PATH = '#/intro';
export const STORE_BASE_PATH = 'routeWidget/';
export const DEFAULT_ROUTE = '/';
export const STOPS_ROUTE = '/route-stops';
export const SUMMARY_ROUTE = '/route-summary';
export const API_HELLO = '/hello';
export const DATE_FORMAT = 'YYYY-MM-DD';
export const TIME_FORMAT = 'HH:mm';
export const ZOOM_CENTER = 18;
export const WINDOW_BOUNDING_BOX_DEGREES = 0.2;
export const MAX_AUTO_SUGGEST_ITEMS = 5;
export const MAX_AUTO_SUGGEST_RADIUS = 50000; // in meters
export const POWER_LOW = 150; // in kW
export const POWER_MEDIUM = 350; // in kW
export const POWER_HIGH = 750; // in kW
export const FEATURE_TOGGLES_POLLING_FREQ = 60; // in seconds
export const DEFAULT_VEHICLE_PROFILE = 'man-etgx-4x2-tractor-semitrailer';
export const ASSET_TAB_SUMMARY = 'SUMMARY';
export const ASSET_TAB_HISTORY = 'HISTORY';
export const ASSET_TAB_ACTIVITY = 'ACTIVITY';
export const ASSET_TAB_TOURS = 'TOURS';
export const ASSET_TAB_REMOTE_DOWNLOAD = 'REMOTE_DOWNLOAD';
export const ASSET_TAB_NOTIFICATIONS = 'NOTIFICATIONS';
export const ASSET_TAB_UNIT = 'UNIT';
export const ASSET_TAB_RANGE = 'RANGE';
export const ASSET_TAB_CHAT = 'CHAT';

export const Unit = {
    KWH: 'kWh',
    currency: '€',
    weight: 'kg',
    tons: 't',
    km: 'km',
    meters: 'm',
    pct: '%',
    temperature: 'ºC',
};

export const HereSettings = {
    apikey: config.backend.MAP_SERVICE_API_KEY,
};

export const MarketplaceSettings = {
    URL: config.backend.MARKETPLACE_SERVICE,
    BUY_BUTTON_URL: config.backend.MARKETPLACE_BUY_BUTTON_SERVICE,
    productId: 'man-sku00000085',
    defaultLevel: '10',
    internalCampaign: 'livemonitor-route-widget-man-smart-route-upselling',
};

export const SmartRouteWebApiService = {
    serviceName: 'smart-route-web-api',
    apiVersion: {
        v1: 'v1',
        latest: 'v1',
    },
    endpoints: {
        assetData: '/asset-data',
        route: '/route',
        accountSubscription: '/subscription/account',
    },
};

export const API = {
    serviceName: 'smart-route-web-api',
    version: {
        v1: 'v1',
        latest: 'v1',
    },
    endpoints: {
        assetData: '/asset-data',
        route: '/route',
        accountSubscription: '/subscription/account',
        assetSubscription: 'subscription/asset',
        permittedAssets: '/permitted-assets',
        accountGroups: '/tags/groups',
        chargePoints: '/charging-station/list',
        chargeStation: '/charging-station/',
        chargeStationNearAddress: '/charging-station/nearest',
        chargeStationAutocomplete: '/charging-station/autocomplete',
        chargeStationOperators: '/charging-station/operators',
        isoline: '/isoline',
        vehicleProfiles: '/vehicle-profiles',
        featureToggles: '/feature-toggles',
    },
    parameters: {
        limit: 1000,
    },
};

export const BASE_URL = `${config.backend.BACKEND_SERVICE}/${API.serviceName}/${API.version.latest}`;

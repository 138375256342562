import classNames from 'classnames';

interface AddWaypointButtonProps {
    onClick: () => void;
}

export const AddWaypointButton = ({ onClick }: AddWaypointButtonProps) => {
    const wrapperClassNames = classNames(
        'display-flex',
        'gap-5',
        'margin-left-15',
        'align-items-center',
        'height-15',
        'opacity-0',
        'hover-opacity-100',
        'cursor-pointer'
    );

    const dividerWrapperClassNames = classNames('margin-0', 'border-width-1', 'border-color-light');

    return (
        <div className={wrapperClassNames} onClick={onClick}>
            <div style={{ flexGrow: 1 }}>
                <hr className={dividerWrapperClassNames} />
            </div>
            <div>
                <span className="rioglyph rioglyph-plus-light text-color-dark line-height-20" />
            </div>
            <div style={{ flexGrow: 1 }}>
                <hr className={dividerWrapperClassNames} />
            </div>
        </div>
    );
};

import Pager from '@rio-cloud/rio-uikit/Pager';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../configuration/setup/hooks';
import { selectCurrentStep, selectSecondStepDisabled } from '../../store/app/appSelectors';
import { NavigationStep, updateStep } from '../../store/app/appSlice';
import { routes } from '../../routes/Router';
import { FormattedMessage } from 'react-intl';
import { selectIsEV } from '../../store/vehicleProfile/vehicleProfileSelectors';

export const labelNext = 'intl-msg:smartRoute.generic.next';
export const labelPrev = 'intl-msg:smartRoute.generic.previous';

export const BottomNavigation = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const isSecondStepDisabled = useAppSelector(selectSecondStepDisabled);
    const currentStep = useAppSelector(selectCurrentStep);
    const isEV = useAppSelector(selectIsEV).value;

    const handleNavigation = (newStep: NavigationStep) => {
        dispatch(updateStep(newStep));
        navigate(routes[newStep]);
    };

    return (
        <div
            className="display-flex flex-1 gap-15 justify-content-between margin-15"
            data-testid="bottom-navigation-container"
        >
            {currentStep > NavigationStep.SettingsStep && (
                <Pager
                    onClick={() => handleNavigation(currentStep - (isEV ? 1 : 2))}
                    variant={Pager.VARIANT_COMPACT}
                    title={<FormattedMessage id={labelPrev} />}
                    className="align-self-start"
                    data-testid="step-prev"
                    alignRight
                />
            )}

            {currentStep < NavigationStep.SummaryStep && (
                <Pager
                    onClick={() => handleNavigation(currentStep + (isEV ? 1 : 2))}
                    variant={Pager.VARIANT_COMPACT}
                    title={<FormattedMessage id={labelNext} />}
                    className="align-self-end margin-left-auto"
                    disabled={isSecondStepDisabled}
                    data-testid="step-next"
                />
            )}
        </div>
    );
};

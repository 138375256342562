import { http, HttpResponse } from 'msw';

const URL = 'https://autosuggest.search.hereapi.com/v1/autosuggest';

export default http.get(URL, ({ params }) => {
    const data = {
        items: [autosuggestAddressData1, autosuggestAddressData2, autosuggestAddressData3],
        queryTerms: [],
    };

    return HttpResponse.json(data);
});

export const autosuggestAddressData1 = {
    title: 'Deutsches Museum',
    id: 'here:pds:place:276u281z-da9f60bd0ba14ba7a1fbfb6afe13b2c9',
    resultType: 'place',
    address: {
        label: 'Deutsches Museum, Museumsinsel 1, 80538 München, Deutschland',
    },
    position: {
        lat: 48.13138,
        lng: 11.58561,
    },
    access: [
        {
            lat: 48.13156,
            lng: 11.58533,
        },
    ],
    distance: 820,
    categories: [
        {
            id: '300-3100-0000',
            name: 'Museum',
            primary: true,
        },
        {
            id: '300-3000-0000',
            name: 'Sehenswürdigkeit',
        },
        {
            id: '300-3000-0023',
            name: 'Touristenattraktion',
        },
        {
            id: '300-3100-0026',
            name: 'Wissenschaftsmuseum',
        },
        {
            id: '900-9300-0221',
            name: 'Wohnhaus',
        },
    ],
    references: [
        {
            supplier: {
                id: 'core',
            },
            id: '50612200',
        },
        {
            supplier: {
                id: 'core',
            },
            id: '50612554',
        },
        {
            supplier: {
                id: 'tripadvisor',
            },
            id: '190295',
        },
        {
            supplier: {
                id: 'yelp',
            },
            id: 'B2tvXOyrPQDnSebyU9UHhA',
        },
    ],
    highlights: {
        title: [
            {
                start: 0,
                end: 2,
            },
        ],
        address: {
            label: [
                {
                    start: 0,
                    end: 2,
                },
            ],
        },
    },
};

export const autosuggestAddressData2 = {
    title: 'Frauenkirche (Der Münchner Dom)',
    id: 'here:pds:place:276u281z-2a7c37d9c78c46338fc225146a9ec8c2',
    resultType: 'place',
    address: {
        label: 'Frauenkirche, Frauenplatz 12, 80331 München, Deutschland',
    },
    position: {
        lat: 48.13885,
        lng: 11.57457,
    },
    access: [
        {
            lat: 48.13875,
            lng: 11.57438,
        },
    ],
    distance: 349,
    categories: [
        {
            id: '300-3200-0030',
            name: 'Kirche',
            primary: true,
        },
        {
            id: '300-3000-0025',
            name: 'Historisches Bauwerk',
        },
        {
            id: '300-3200-0000',
            name: 'Religiöse Einrichtung',
        },
        {
            id: '900-9300-0000',
            name: 'Gebäude',
        },
    ],
    references: [
        {
            supplier: {
                id: 'core',
            },
            id: '50604468',
        },
        {
            supplier: {
                id: 'core',
            },
            id: '50612213',
        },
        {
            supplier: {
                id: 'core',
            },
            id: '800756441',
        },
        {
            supplier: {
                id: 'tripadvisor',
            },
            id: '190285',
        },
        {
            supplier: {
                id: 'yelp',
            },
            id: 'Cyds2dsEjG8pfckZ6vtobg',
        },
    ],
    highlights: {
        title: [
            {
                start: 14,
                end: 16,
            },
        ],
        address: {
            label: [],
        },
    },
};

export const autosuggestAddressData3 = {
    title: 'Deutsches Theater',
    id: 'here:pds:place:276u281z-0cca1141a5de439498888b76e79a80af',
    resultType: 'place',
    address: {
        label: 'Deutsches Theater, Schwanthalerstraße 15, 80336 München, Deutschland',
    },
    position: {
        lat: 48.13723,
        lng: 11.56232,
    },
    access: [
        {
            lat: 48.13741,
            lng: 11.56232,
        },
    ],
    distance: 1134,
    categories: [
        {
            id: '500-5000-0053',
            name: 'Hotel',
            primary: true,
        },
        {
            id: '200-2200-0000',
            name: 'Theater, Musik und Kultur',
        },
        {
            id: '200-2200-0020',
            name: 'Theater',
        },
    ],
    references: [
        {
            supplier: {
                id: 'core',
            },
            id: '50605363',
        },
        {
            supplier: {
                id: 'core',
            },
            id: '50611176',
        },
        {
            supplier: {
                id: 'tripadvisor',
            },
            id: '1073773',
        },
        {
            supplier: {
                id: 'tripadvisor',
            },
            id: '605222',
        },
        {
            supplier: {
                id: 'yelp',
            },
            id: 'bTKCR0FIsdkgApeT5YZ-6A',
        },
        {
            supplier: {
                id: 'yelp',
            },
            id: 'mq3HDa1Xh2Rd5Cu_sEuWbA',
        },
    ],
    highlights: {
        title: [
            {
                start: 0,
                end: 2,
            },
        ],
        address: {
            label: [
                {
                    start: 0,
                    end: 2,
                },
            ],
        },
    },
};

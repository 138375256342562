import { trace } from '../../configuration/setup/trace';
import { ADD_ROUTE_STATION_EVENT, addStationWaypointHandler } from './events/addStationWaypoint';
import { CENTER_ACTIVE_ASSET_EVENT, centerActiveAssetHandler } from './events/centerActiveAsset';
import { MAP_CHANGED_EVENT, mapChangedHandler } from './events/mapChanged';
import { SELECT_ASSET_EVENT, selectAssetHandler } from './events/selectAsset';
import { SELECT_GEOFENCES_EVENT, selectGeofencesHandler } from './events/selectGeofences';
import { SELECT_POIS_EVENT, selectPoisHandler } from './events/selectPois';
import { SET_MAP_CONTEXT_EVENT, setMapContextHandler } from './events/setMapContext';
import { SET_ROUTING_END_POINT, setRouteEndWaypointHandler } from './events/setRouteEndWaypoint';
import { SET_ROUTING_START_POINT, setRouteStartWaypointHandler } from './events/setRouteStartWaypoint';
import { SET_WIDGET_ID_EVENT, setWidgetIdHandler } from './events/setWidgetId';
import { VISIBLE_WIDGETS_EVENT, visibleWidgetsHandler } from './events/visibleWidgets';

const EVENT_PREFIX = 'EVENT';

export const sendMessage = (message: any) => {
    const referrer = document.referrer;
    if (referrer) {
        window.parent.postMessage(message, referrer);
    }
};

export const sendWidgetMessage = (targetOrigin: string, message: any) => {
    if (!window.top) {
        return;
    }
    const frames = window.top.frames;
    const windows = Object.values(frames).slice(1);
    windows.forEach((window) => window.postMessage(message, targetOrigin));
};

export const processWidgetAction = (event: MessageEvent) => {
    const actionType = event.data.type;
    if (!actionType || !actionType.startsWith(EVENT_PREFIX)) {
        return;
    }
    trace.debug('widget event:', event.data);

    const payload = event.data.payload;

    switch (actionType) {
        case SELECT_ASSET_EVENT:
            selectAssetHandler(payload);
            break;
        case SET_WIDGET_ID_EVENT:
            setWidgetIdHandler(payload);
            break;
        case VISIBLE_WIDGETS_EVENT:
            visibleWidgetsHandler(payload);
            break;
        case CENTER_ACTIVE_ASSET_EVENT:
            centerActiveAssetHandler(payload);
            break;
        case MAP_CHANGED_EVENT:
            mapChangedHandler(payload);
            break;
        case SET_MAP_CONTEXT_EVENT:
            setMapContextHandler(payload);
            break;
        case SELECT_POIS_EVENT:
            selectPoisHandler(payload);
            break;
        case SELECT_GEOFENCES_EVENT:
            selectGeofencesHandler(payload);
            break;
        case ADD_ROUTE_STATION_EVENT:
            addStationWaypointHandler(payload);
            break;
        case SET_ROUTING_START_POINT:
            setRouteStartWaypointHandler(event.data, payload);
            break;
        case SET_ROUTING_END_POINT:
            setRouteEndWaypointHandler(event.data, payload);
            break;
        default:
            trace.warn(`Action of type "${actionType}" is not a valid widget action.`);
    }
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { STORE_BASE_PATH } from '../../common/constants';
import messagesEN from '../../configuration/translations/en-GB.json';
import { DEFAULT_LOCALE, getSupportedLocale } from './lang';

export type DisplayMessages = Record<string, string>;

interface CombinedMessages {
    [key: string]: DisplayMessages;
}

export interface LangState {
    allMessages: CombinedMessages;
    displayMessages?: DisplayMessages;
    displayLocale: string;
}

interface MessagesPayload {
    locale: string;
    displayMessages: DisplayMessages;
}

export const defaultMessages = {
    [DEFAULT_LOCALE]: messagesEN,
};

const initialState: LangState = {
    allMessages: defaultMessages,
    displayMessages: undefined,
    displayLocale: DEFAULT_LOCALE,
};

const langSlice = createSlice({
    name: STORE_BASE_PATH + 'lang',
    initialState,
    reducers: {
        localeChanged: (state, action: PayloadAction<string>) => {
            const preferredLocale = action.payload;
            const displayLocale = getSupportedLocale(preferredLocale);

            state.displayLocale = displayLocale;
            state.displayMessages = state.allMessages[displayLocale];
        },
        displayMessagesFetched: (state, action: PayloadAction<MessagesPayload>) => {
            const { locale, displayMessages } = action.payload;

            state.allMessages = {
                ...state.allMessages,
                [locale]: displayMessages,
            };
            state.displayMessages = displayMessages;
            state.displayLocale = locale;
        },
    },
});

export const { localeChanged, displayMessagesFetched } = langSlice.actions;

export default langSlice.reducer;

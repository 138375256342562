import Button from '@rio-cloud/rio-uikit/Button';
import classNames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../configuration/setup/hooks';
import { fetchRoutes } from '../../features/fetchData/fetchRoutes';
import { selectIsFetchRoutesInProgress } from '../../store/route/routeSelectors';
import { selectAreWaypointsInvalid } from '../../store/search/searchSelectors';
import { selectHasValidBooking } from '../../store/vehicleProfile/vehicleProfileSelectors';
import { showMapNotification } from '../../store/widget/actions/showMapNotification';

export const SubmitForm = () => {
    const dispatch = useAppDispatch();
    const intl = useIntl();

    const isLoading = useAppSelector(selectIsFetchRoutesInProgress);
    const areWaypointsInvalid = useAppSelector(selectAreWaypointsInvalid);
    const hasValidBooking = useAppSelector(selectHasValidBooking);
    const isDisabled = areWaypointsInvalid || !hasValidBooking;

    const calculateButtonClassNames = classNames(
        'btn btn-primary btn-component width-100pct',
        isLoading && 'btn-loading'
    );

    const submitHandler = async () => {
        try {
            await dispatch(fetchRoutes);
        } catch (error) {
            const changeRouteSettings = intl.formatMessage({
                id: 'intl-msg:smartRoute.notification.pleaseChangeRouteSettings',
            });
            const routeCalculationFail = intl.formatMessage({
                id: 'intl-msg:smartRoute.notification.routeCalculationFailed',
            });
            showMapNotification('warning', changeRouteSettings, routeCalculationFail);
        }
    };

    return (
        <div className="" data-testid="submit-form-container">
            <Button
                disabled={isDisabled}
                className={calculateButtonClassNames}
                bsStyle={Button.PRIMARY}
                onClick={submitHandler}
            >
                <span data-testid="submit-computation">
                    <FormattedMessage id="intl-msg:smartRoute.generic.calculate" />
                </span>
            </Button>
        </div>
    );
};

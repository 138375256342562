import { createSelector } from 'reselect';
import { RootState } from '../../configuration/setup/store';

export const selectVehicleProfile = (state: RootState) => state.vehicleProfile;
export const selectIsEV = createSelector(selectVehicleProfile, (state) => state.isEV);
export const selectIsSelectedVehicleEv = createSelector(
    selectVehicleProfile,
    (state) => state.assetLocked?.details?.details_bev
);
export const selectDefaultEvProfile = createSelector(selectVehicleProfile, (state) => state.defaultEvProfile);
export const selectIsProfileSubmited = createSelector(selectVehicleProfile, (state) => state.isProfileSubmited);
export const selectOutdoorTemperature = createSelector(
    selectVehicleProfile,
    (state) => state.averageOutdoorTemperature.value
);
export const selectVehiclePayload = createSelector(selectVehicleProfile, (state) => state.vehiclePayload.value);

export const selectVehicleAxlesField = createSelector(selectVehicleProfile, (state) => state.vehicleAxles);
export const selectTrailerAxlesField = createSelector(selectVehicleProfile, (state) => state.trailerAxles);
export const selectWeightPerAxleField = createSelector(selectVehicleProfile, (state) => state.weightPerAxle);
export const selectWeightPerAxleGroupField = createSelector(selectVehicleProfile, (state) => state.weightPerAxleGroup);
export const selectEmissionClassification = createSelector(
    selectVehicleProfile,
    (state) => state.emissionClassification.value
);
export const selectHazardClassification = createSelector(
    selectVehicleProfile,
    (state) => state.hazardClassification.value
);
export const selectProfileType = createSelector(selectVehicleProfile, (state) => state.profileType.value);
export const selectTruckTypeField = createSelector(selectVehicleProfile, (state) => state.truckType);
export const selectTrailerField = createSelector(selectVehicleProfile, (state) => state.trailer);
export const selectTunnelCategory = createSelector(selectVehicleProfile, (state) => state.tunnelCategory.value);
export const selectBatteryCapacity = createSelector(selectVehicleProfile, (state) => state.batteryCapacity.value);
export const selectLengthField = createSelector(selectVehicleProfile, (state) => state.length);
export const selectHeightField = createSelector(selectVehicleProfile, (state) => state.height);
export const selectWidthField = createSelector(selectVehicleProfile, (state) => state.width);
export const selectTotalWeightField = createSelector(selectVehicleProfile, (state) => state.totalWeight);
export const selectEmptyWeightField = createSelector(selectVehicleProfile, (state) => state.emptyWeight);
export const selectCargoWeightField = createSelector(selectVehicleProfile, (state) => state.cargoWeight);
export const selectPayloadWeightField = createSelector(selectVehicleProfile, (state) => state.payloadWeight);
export const selectHasValidBooking = createSelector(
    selectVehicleProfile,
    (state) => state.currentBookingState === undefined || state.currentBookingState === 'ACTIVE'
);

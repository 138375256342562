import { createSelector } from 'reselect';
import { RootState } from '../../configuration/setup/store';

export const selectRoute = (state: RootState) => state.route;
export const selectRouteDeparture = createSelector(selectRoute, (state) => state.departure);
export const selectRouteConstraints = createSelector(selectRoute, (state) => state.constraints);
export const selectVehicleCosts = createSelector(selectRoute, (state) => state.vehicleCosts);
export const selectEnergyCosts = createSelector(selectRoute, (state) => state.energyCosts);
export const selectSuggestedRoutes = createSelector(selectRoute, (state) => state.suggestedRoutes);
export const selectSelectedRoute = createSelector(selectRoute, (state) => state.selectedRoute);
export const selectIsFetchRoutesInProgress = createSelector(selectRoute, (state) => state.fetchRoutesInProgress);
export const selectIsFetchRangeInProgress = createSelector(selectRoute, (state) => state.fetchRangeInProgress);
export const selectShowVehicleRange = createSelector(selectRoute, (state) => state.showVehicleRange);
export const selectTriggerRangeFetch = createSelector(selectRoute, (state) => state.triggerRangeFetch);
export const selectIsRoutingActive = createSelector(selectRoute, (state) => state.isRoutingActive);
export const selectCurrentRoute = createSelector(selectRoute, (state) => {
    if (state.suggestedRoutes && state.selectedRoute) {
        return state.suggestedRoutes.routes[state.selectedRoute];
    } else {
        return null;
    }
});

import IframeResizer from 'iframe-resizer-react';
import { MarketplaceSettings } from '../../common/constants';

// eslint-disable-next-line max-len
const UPSELLING_URL = `${MarketplaceSettings.URL}?productId=${MarketplaceSettings.productId}&level=${MarketplaceSettings.defaultLevel}&noBorder=true`;

export const Upselling = () => {
    return (
        <div className="support-marker padding-x-15 padding-top-15">
            <IframeResizer
                src={UPSELLING_URL}
                height={0}
                data-testid="UpsellingWidget"
                checkOrigin={false}
                resizeFrom="child"
                style={{ width: '1px', minWidth: '100%', minHeight: '100%', border: '0' }}
            />
        </div>
    );
};

import { createSelector } from 'reselect';
import { RootState } from '../../configuration/setup/store';

export const selectData = (state: RootState) => state.data;
export const selectPois = createSelector(selectData, (state) => state.pois);
export const selectGeofences = createSelector(selectData, (state) => state.geofences);
export const selectsGeoBooked = createSelector(selectData, (state) => state.isGeoBooked);
export const selectIsFetchPoisFailed = createSelector(selectData, (state) => state.fetchPoisFailed);
export const selectIsFetchGeofencesFailed = createSelector(selectData, (state) => state.fetchGeofencesFailed);
export const selectIsFetchHereSettingsFailed = createSelector(selectData, (state) => state.fetchHereSettingsFailed);
export const selectHereSettings = createSelector(selectData, (state) => state.hereSettings);
export const selectHereApiKey = createSelector(selectHereSettings, (state) => state?.apikey);

import {
    ASSET_TAB_ACTIVITY,
    ASSET_TAB_CHAT,
    ASSET_TAB_HISTORY,
    ASSET_TAB_NOTIFICATIONS,
    ASSET_TAB_RANGE,
    ASSET_TAB_REMOTE_DOWNLOAD,
    ASSET_TAB_SUMMARY,
    ASSET_TAB_TOURS,
    ASSET_TAB_UNIT,
} from './constants';

export interface Coordinates {
    latitude: number;
    longitude: number;
}

export interface Coords {
    lat: number;
    lng: number;
}

export type CoordsArray = number[];

export interface WeatherInfo {
    sky_info: string;
    temperature: number;
    wind_speed: number;
    wind_direction: string;
}

export interface Plug {
    type: string;
    total: number;
    estimated_available: number;
}

export interface Editable {
    isEditable: boolean;
}

export interface EditableField<T> extends Editable {
    value?: T;
}

export type AssetId = string;

export type MapPointData = {
    name?: string;
    address?: string;
    coordinates: Coords;
};

export enum GeofenceType {
    Circle = 'CIRCLE',
    Polygon = 'POLYGON',
}

export enum RouteOptimization {
    FASTEST = 'fastest',
    SHORTEST = 'shortest',
    CHEAPEST = 'cheapest',
}

export const MapContext = {
    MAP_CONTEXT_DEFAULT: 'default',
    MAP_CONTEXT_HISTORY: 'history',
    MAP_CONTEXT_ROUTEPLANNING: 'routeplanning',
};

export type EventAssetSidebarTab =
    | typeof ASSET_TAB_SUMMARY
    | typeof ASSET_TAB_HISTORY
    | typeof ASSET_TAB_ACTIVITY
    | typeof ASSET_TAB_TOURS
    | typeof ASSET_TAB_REMOTE_DOWNLOAD
    | typeof ASSET_TAB_NOTIFICATIONS
    | typeof ASSET_TAB_UNIT
    | typeof ASSET_TAB_RANGE
    | typeof ASSET_TAB_CHAT;

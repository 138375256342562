import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserProfile as Profile } from 'oidc-client-ts';
import { STORE_BASE_PATH } from '../../common/constants';
import { accessToken } from './accessToken';

export type AccessToken = string | undefined | null;

export interface AccessTokenState {
    accessToken: AccessToken;
    idToken: Profile | null;
}

const initialState: AccessTokenState = {
    accessToken: accessToken.getAccessToken(),
    idToken: null,
};

const tokenHandlingSlice = createSlice({
    name: STORE_BASE_PATH + 'tokenHandling',
    initialState,
    reducers: {
        accessTokenStored: (state, action: PayloadAction<AccessToken>) => {
            state.accessToken = action.payload;
        },
        idTokenStored: (state, action: PayloadAction<Profile>) => {
            state.idToken = action.payload;
        },
    },
});

export const { accessTokenStored, idTokenStored } = tokenHandlingSlice.actions;

export default tokenHandlingSlice.reducer;

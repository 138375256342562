import SteppedProgressBar from '@rio-cloud/rio-uikit/SteppedProgressBar';
import { useAppDispatch, useAppSelector } from '../../configuration/setup/hooks';
import { selectCurrentStep, selectDisabledSteps } from '../../store/app/appSelectors';
import { selectIsEV } from '../../store/vehicleProfile/vehicleProfileSelectors';
import { NavigationStep, getRouteFromStep, updateStep } from '../../store/app/appSlice';
import { useNavigate } from 'react-router';

export const TopNavigation = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const disabledPages = useAppSelector(selectDisabledSteps);
    const isEV = useAppSelector(selectIsEV).value;

    const currentStep = useAppSelector(selectCurrentStep);
    const handleSelectedChanged = (step: NavigationStep) => {
        navigate(getRouteFromStep(step));
        dispatch(updateStep(step));
    };

    return (
        <SteppedProgressBar
            variant="circle"
            selectedStepNumber={currentStep}
            onSelectedChanged={handleSelectedChanged}
            disableFollowingPages={disabledPages}
            mobileBreakpoint={false}
            labels={[
                { icon: <i className="rioglyph rioglyph-cog" />, labelWrapperClassName: '' },
                {
                    icon: <i className="rioglyph rioglyph-route-option" />,
                    labelWrapperClassName: isEV === false ? 'disabled' : '',
                },
                {
                    icon: <i className="rioglyph rioglyph-detail-view" />,
                    labelWrapperClassName: '',
                },
            ]}
            className="margin-15"
        />
    );
};

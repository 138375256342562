import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { STORE_BASE_PATH } from '../../common/constants';
import { EditableField } from '../../common/types';
import { TUNNEL_ALL_OPTION, TunnelCategoryTypes } from '../../containers/VehicleOptions/AdditionalRoutingOptions';
import { AssetData, VehicleProfileDetails, VehicleProfilePackWeight } from '../facade/facadeApi';
import { defaultVehicleProfile } from './defaultVehicleProfile';
import { EmissionClass, HazardClass, PlugType, VehicleTypes } from './vehicleProfileTypes';

export interface VehicleProfile {
    isProfileSubmited: boolean;
    currentBookingState?: string;
    defaultEvProfile?: string;
    assetLocked?: AssetData;
    isEV: EditableField<boolean>;
    profileType: EditableField<string>;
    truckType: EditableField<VehicleTypes>;
    numberAttachedTrailers: number;
    trailer: EditableField<boolean>;
    length: EditableField<number>;
    height: EditableField<number>;
    width: EditableField<number>;
    weightPerAxle: EditableField<number>;
    weightPerAxleGroup: EditableField<number>;
    totalWeight: EditableField<number>;
    emptyWeight: EditableField<number>;
    cargoWeight: EditableField<number>;
    payloadWeight: EditableField<number>;
    vehicleAxles: EditableField<number>;
    trailerAxles: EditableField<number>;
    batteryCapacity: EditableField<number>;
    emissionClassification: EditableField<EmissionClass>;
    hazardClassification: EditableField<HazardClass>;
    tunnelCategory: EditableField<TunnelCategoryTypes>;
    vehiclePayload: EditableField<number>;
    averageOutdoorTemperature: EditableField<number>;
    minSoC: EditableField<number>;
    maxSoC: EditableField<number>;
    originSoC: EditableField<number>;
    destinationSoC: EditableField<number>;
    plugType: EditableField<PlugType>;
    costPerKm: EditableField<number>;
    costPerKWh: EditableField<number>;
}

export const initialState: VehicleProfile = {
    isProfileSubmited: false,
    isEV: { value: true, isEditable: true },
    profileType: { value: undefined, isEditable: true },
    truckType: { value: undefined, isEditable: true },
    numberAttachedTrailers: 0,
    trailer: { value: undefined, isEditable: true },
    length: { value: undefined, isEditable: true },
    height: { value: undefined, isEditable: true },
    width: { value: undefined, isEditable: true },
    totalWeight: { value: undefined, isEditable: true },
    emptyWeight: { value: undefined, isEditable: true },
    cargoWeight: { value: undefined, isEditable: true },
    payloadWeight: { value: 0, isEditable: true },
    weightPerAxle: { value: undefined, isEditable: true },
    weightPerAxleGroup: { value: undefined, isEditable: true },
    vehicleAxles: { value: undefined, isEditable: true },
    trailerAxles: { value: undefined, isEditable: true },
    batteryCapacity: { value: undefined, isEditable: true },
    emissionClassification: { value: undefined, isEditable: true },
    hazardClassification: { value: defaultVehicleProfile.hazardClassification, isEditable: true },
    tunnelCategory: { value: TUNNEL_ALL_OPTION, isEditable: true },
    vehiclePayload: { value: undefined, isEditable: true },
    averageOutdoorTemperature: { value: defaultVehicleProfile.averageOutdoorTemperature, isEditable: true },
    minSoC: { value: defaultVehicleProfile.minSoC, isEditable: true },
    maxSoC: { value: defaultVehicleProfile.maxSoC, isEditable: true },
    originSoC: { value: defaultVehicleProfile.originSoC, isEditable: true },
    destinationSoC: { value: defaultVehicleProfile.destinationSoC, isEditable: true },
    plugType: { value: defaultVehicleProfile.plugType, isEditable: true },
    costPerKm: { value: defaultVehicleProfile.costPerKm, isEditable: true },
    costPerKWh: { value: defaultVehicleProfile.costPerKWh, isEditable: true },
};

export const vehicleProfileSlice = createSlice({
    name: STORE_BASE_PATH + 'vehicleProfile',
    initialState,
    reducers: {
        clearVehicleProfileData: () => initialState,
        resetProfileDetails: (state) => {
            state.truckType = { value: undefined, isEditable: true };
            state.numberAttachedTrailers = 0;
            state.trailer = { value: undefined, isEditable: true };
            state.length = { value: undefined, isEditable: true };
            state.height = { value: undefined, isEditable: true };
            state.width = { value: undefined, isEditable: true };
            state.payloadWeight = { value: 0, isEditable: true };
            state.totalWeight = { value: undefined, isEditable: false };
            state.emptyWeight = { value: undefined, isEditable: true };
            state.cargoWeight = { value: undefined, isEditable: true };
            state.weightPerAxle = { value: undefined, isEditable: true };
            state.weightPerAxleGroup = { value: undefined, isEditable: true };
            state.vehicleAxles = { value: undefined, isEditable: true };
            state.trailerAxles = { value: undefined, isEditable: true };
            state.batteryCapacity = { value: undefined, isEditable: true };
        },
        changeIsProfileSubmited: (state, action) => {
            state.isProfileSubmited = action.payload;
        },
        changeCurrentBookingState: (state, action) => {
            state.currentBookingState = action.payload;
        },
        changeDefaultEvProfile: (state, action) => {
            state.defaultEvProfile = action.payload;
        },
        changeProfileDetails: (state, action: PayloadAction<VehicleProfileDetails>) => {
            const details = action.payload;

            state.truckType = { value: details.vehicleType, isEditable: false };

            state.numberAttachedTrailers = details.numberAttachedTrailers;
            state.trailer = { value: details.numberAttachedTrailers > 0, isEditable: false };

            state.length = { value: details.length, isEditable: false };

            state.height = { value: details.height, isEditable: false };

            state.width = { value: details.width, isEditable: false };

            state.payloadWeight = { value: 0, isEditable: false };

            const packWeigth = details.emptyWeightFreightTrain.find(
                (it: VehicleProfilePackWeight) => it.packs === details.defaultInstalledBatteryPackCount
            );
            if (packWeigth) {
                const packWeigthValue = packWeigth.weight * 1000;
                state.emptyWeight = { value: packWeigthValue, isEditable: false };
                state.totalWeight = { value: packWeigthValue + (state.cargoWeight.value ?? 0), isEditable: false };
            }
            state.batteryCapacity.value = details.batteryCapacityPerPack * details.defaultInstalledBatteryPackCount;

            state.weightPerAxle = { value: details.maxWeightPerAxle * 1000, isEditable: false };
            const weightPerAxleGroup = details.maxWeightPerAxleGroup ? details.maxWeightPerAxleGroup * 1000 : undefined;
            state.weightPerAxleGroup = { value: weightPerAxleGroup, isEditable: false };

            state.vehicleAxles = { value: details.axlesVehicle, isEditable: false };

            state.trailerAxles = { value: details.axlesTrailer ?? 0, isEditable: false };
        },
        changeAssetLocked: (state, action) => {
            state.assetLocked = action.payload;
        },
        changeIsEV: (state, action) => {
            const isEV = action.payload;
            state.isEV = { value: isEV, isEditable: true };
            state.profileType.value = isEV ? state.defaultEvProfile : undefined;
            if (!isEV) {
                state.emissionClassification = {
                    value: defaultVehicleProfile.emissionClassification,
                    isEditable: true,
                };
            }
        },
        changeVehicleType: (state, action) => {
            state.profileType = { value: action.payload, isEditable: true };
        },
        changeTruckType: (state, action) => {
            state.truckType = { value: action.payload, isEditable: true };
        },
        changeTrailer: (state, action) => {
            state.trailer = { value: action.payload, isEditable: true };
        },
        changeLength: (state, action) => {
            state.length = { value: action.payload, isEditable: true };
        },
        changeHeight: (state, action) => {
            state.height = { value: action.payload, isEditable: true };
        },
        changeWidth: (state, action) => {
            state.width = { value: action.payload, isEditable: true };
        },
        changeTotalWeight: (state, action) => {
            state.totalWeight = { value: action.payload, isEditable: true };
        },
        changeEmptyWeight: (state, action) => {
            const emptyWeightValue = action.payload;
            state.emptyWeight.value = emptyWeightValue;
            state.totalWeight.value = emptyWeightValue + (state.cargoWeight.value ?? 0);
        },
        changeCargoWeight: (state, action) => {
            const cargoWeightValue = action.payload;
            state.cargoWeight.value = cargoWeightValue;
            state.totalWeight.value = cargoWeightValue + (state.emptyWeight.value ?? 0);
        },
        changeWeightPerAxle: (state, action) => {
            state.weightPerAxle.value = action.payload;
        },
        changeWeightPerAxleGroup: (state, action) => {
            state.weightPerAxleGroup.value = action.payload;
        },
        changeVehicleAxles: (state, action) => {
            state.vehicleAxles = { value: action.payload, isEditable: true };
        },
        changeTrailerAxles: (state, action) => {
            state.trailerAxles = { value: action.payload, isEditable: true };
        },
        changeBatteryCapacity: (state, action) => {
            const profileDetails = action.payload.profileDetails;
            const batteryPack = Number(action.payload.batteryPack);

            const packWeigth = profileDetails.emptyWeightFreightTrain.find(
                (it: VehicleProfilePackWeight) => it.packs === batteryPack
            );
            if (packWeigth) {
                const packWeigthValue = packWeigth.weight * 1000;
                state.emptyWeight = { value: packWeigthValue, isEditable: false };
                state.totalWeight = { value: packWeigthValue + (state.cargoWeight.value ?? 0), isEditable: false };
            }
            state.batteryCapacity.value = action.payload.batteryPack;
        },
        changeEmissionClassification: (state, action) => {
            state.emissionClassification = { value: action.payload, isEditable: true };
        },
        changeHazardClassification: (state, action) => {
            state.hazardClassification = { value: action.payload, isEditable: true };
        },
        changeTunnelCategory: (state, action) => {
            state.tunnelCategory.value = action.payload;
        },
        changeVehiclePayload: (state, action) => {
            const payloadWeight = action.payload;
            state.payloadWeight.value = payloadWeight;
            state.totalWeight.value = (state.emptyWeight.value ?? 0) + payloadWeight;
        },
        changeAverageOutdoorTemperature: (state, action) => {
            state.averageOutdoorTemperature = { value: action.payload, isEditable: true };
        },
        changeMinSoC: (state, action) => {
            state.minSoC = { value: action.payload, isEditable: true };
        },
        changeMaxSoC: (state, action) => {
            state.maxSoC = { value: action.payload, isEditable: true };
        },
        changeOriginSoC: (state, action) => {
            state.originSoC = { value: action.payload, isEditable: true };
        },
        changeDestinationSoC: (state, action) => {
            state.destinationSoC = { value: action.payload, isEditable: true };
        },
        changePlugType: (state, action) => {
            state.plugType = { value: action.payload, isEditable: true };
        },
        changeCostPerKm: (state, action) => {
            state.costPerKm = { value: action.payload, isEditable: true };
        },
        changeCostPerKWh: (state, action) => {
            state.costPerKWh = { value: action.payload, isEditable: true };
        },
    },
});

export const {
    changeCurrentBookingState,
    changeDefaultEvProfile,
    resetProfileDetails,
    changeIsProfileSubmited,
    changeProfileDetails,
    changeAssetLocked,
    changeIsEV,
    changeVehicleType,
    changeTruckType,
    changeTrailer,
    changeLength,
    changeHeight,
    changeWidth,
    changeTotalWeight,
    changeEmptyWeight,
    changeCargoWeight,
    changeWeightPerAxle,
    changeWeightPerAxleGroup,
    changeVehicleAxles,
    changeTrailerAxles,
    changeBatteryCapacity,
    changeEmissionClassification,
    changeHazardClassification,
    changeTunnelCategory,
    changeVehiclePayload,
    changeAverageOutdoorTemperature,
    changeMinSoC,
    changeMaxSoC,
    changeOriginSoC,
    changeDestinationSoC,
    changePlugType,
    changeCostPerKm,
    changeCostPerKWh,
    clearVehicleProfileData,
} = vehicleProfileSlice.actions;

export default vehicleProfileSlice.reducer;

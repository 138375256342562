import { http, HttpResponse } from 'msw';

const URL = 'https://revgeocode.search.hereapi.com/';

export default http.get(URL, ({ params }) => {
    const data = {
    "items": [
        {
            "title": "D958, 55200 Commercy, France",
            "id": "here:af:streetsection:h5q.aLlqfdbn-LgggXntDA",
            "resultType": "street",
            "address": {
                "label": "D958, 55200 Commercy, France",
                "countryCode": "FRA",
                "countryName": "France",
                "stateCode": "GES",
                "state": "Grand Est",
                "county": "Meuse",
                "city": "Commercy",
                "street": "D958",
                "postalCode": "55200"
            },
            "position": {
                "lat": 48.74266,
                "lng": 5.57016
            },
            "distance": 446,
            "mapView": {
                "west": 5.4968,
                "south": 48.71414,
                "east": 5.60285,
                "north": 48.76831
            }
        }
    ]
};

    return HttpResponse.json(data);
});
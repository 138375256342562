import isNil from 'lodash/fp/isNil';
import { MapPointData } from '../../../common/types';
import { coordinatesToString } from '../../../common/utils';
import { store } from '../../../configuration/setup/store';
import { trace } from '../../../configuration/setup/trace';
import { resolveAddress } from '../../../features/fetchData/resolveAddress';
import { Waypoint, WaypointType, waypointChanged } from '../../search/searchSlice';

export const SET_ROUTING_END_POINT = 'EVENT_SET_ROUTING_END_POINT';

export const setRouteEndWaypointHandler = async (event: MessageEvent, mapPointData: MapPointData) => {
    try {
        const resolvedAddress = await store.dispatch(resolveAddress(event));
        store.dispatch(
            waypointChanged({
                id: store.getState().search.waypointsOrder.at(-1),
                address: isNil(resolvedAddress) ? coordinatesToString(mapPointData.coordinates) : resolvedAddress,
                coordinates: mapPointData.coordinates,
                type: WaypointType.ADDITIONAL_STOP,
            } as Waypoint)
        );
    } catch (e) {
        trace.error(e);
    }
};

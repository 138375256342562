import { http, HttpResponse } from 'msw';

const URL = 'https://2d42flfxs4.execute-api.eu-west-1.amazonaws.com/prod/create';

export const validHandler = http.post(URL, () => {
    return HttpResponse.json(null, { status: 201 });
});

export const errorHandler = http.post(URL, () => {
    return new HttpResponse(null, { status: 500 });
});

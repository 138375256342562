import { AssetId } from '../../../common/types';
import { trace } from '../../../configuration/setup/trace';

export const SELECT_GEOFENCES_EVENT = 'EVENT_SELECT_GEOFENCES';

export type SelectGeofencesType = AssetId[];

export const selectGeofencesHandler = (data: SelectGeofencesType) => {
    trace.debug('Select Geofences:', data);
};

export interface FeatureFlag {
    name: string;
    isActive: boolean;
}

export interface ToggleFlag {
    [x: string]: boolean;
}

export const buildFeatureFlagsStructure = (flags: ToggleFlag) => {
    const featureFlags = Object.keys(flags).map((flag) => {
        return { name: flag, isActive: flags[flag] as boolean };
    });
    return featureFlags;
};

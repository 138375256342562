import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import appReducer from '../../store/app/appSlice';
import chargingReducer from '../../store/charging/chargingSlice';
import configReducer from '../../store/config/configSlice';
import dataReducer from '../../store/data/dataSlice';
import { facadeApi } from '../../store/facade/facadeApi';
import langReducer from '../../store/lang/langSlice';
import locationsReducer from '../../store/locations/LocationsSlice';
import loginReducer from '../../store/login/loginSlice';
import routeReducer from '../../store/route/routeSlice';
import searchReducer from '../../store/search/searchSlice';
import tokenReducer from '../../store/tokenHandling/tokenHandlingSlice';
import vehicleProfileReducer from '../../store/vehicleProfile/vehicleProfileSlice';
import widgetReducer from '../../store/widget/widgetSlice';

export const setupStore = () => {
    return configureStore({
        reducer: {
            config: configReducer,
            lang: langReducer,
            app: appReducer,
            login: loginReducer,
            tokenHandling: tokenReducer,

            // Add the generated reducer as a specific top-level slice
            charging: chargingReducer,
            data: dataReducer,
            locations: locationsReducer,
            route: routeReducer,
            search: searchReducer,
            vehicleProfile: vehicleProfileReducer,
            widget: widgetReducer,

            [facadeApi.reducerPath]: facadeApi.reducer,
        },

        // Adding the api middleware enables caching, invalidation, polling,
        // and other useful features of `rtk-query`.
        middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(facadeApi.middleware),

        // TODO disable devTools when the project goes live
        // devTools: config.serviceEnvironment !== 'production'
        devTools: true,
    });
};
export const store = setupStore();

// Infer the `RootState` and `RootDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type RootDispatch = typeof store.dispatch;

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

import Select, { SelectOption } from '@rio-cloud/rio-uikit/Select';
import isNil from 'lodash/fp/isNil';

import React, { ReactElement } from 'react';

export const LabeledSelect = (props: LabeledSelectProps) => {
    const {
        disabled,
        className = '',
        label,
        optionLabels,
        selectedOption,
        toggleButtonLabel,
        placeholder,
        onChange = () => {},
        dropdownClassName,
    } = props;

    const handleOptionChange = (option: SelectOption) => {
        onChange(option.id);
    };

    const value = isNil(selectedOption) ? undefined : [selectedOption];

    return (
        <div className={`form-group margin-bottom-10 ${className}`}>
            <label className="control-label">{label}</label>
            <div className="width-100pct">
                <Select
                    disabled={disabled}
                    options={optionLabels}
                    onChange={handleOptionChange}
                    value={value}
                    selectedOptionText={toggleButtonLabel}
                    placeholder={placeholder}
                    dropdownClassName={dropdownClassName}
                />
            </div>
        </div>
    );
};

interface LabeledSelectProps {
    disabled?: boolean;
    className?: string;
    label: ReactElement;
    optionLabels: SelectOption[];
    selectedOption?: string;
    placeholder?: ReactElement;
    toggleButtonLabel?: string | React.ReactNode;
    onChange?: (selectedOption: string) => void;
    dropdownClassName?: string;
}

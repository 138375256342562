import { store } from '../../../configuration/setup/store';
import { updateWidgetId } from '../widgetSlice';

export const SET_WIDGET_ID_EVENT = 'EVENT_SET_WIDGET_ID';

type SetWidgetIdType = string;

export const setWidgetIdHandler = (data: SetWidgetIdType) => {
    store.dispatch(updateWidgetId(data));
};

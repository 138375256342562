import { Coords } from '../../../common/types';
import { Coordinates, DrivingSection, RouteSection, SectionType } from '../../../store/facade/facadeApi';
import { WaypointWithCoordinates } from '../createRouteParameters';

export type TripDetails = {
    asset_id: string;
    overview: {
        fastest_route?: string;
        cheapest_route?: string;
        shortest_route?: string;
    };
    routes: RouteDetails[];
    timestamp?: string;
};

export interface Route {
    waypoints: WaypointWithCoordinates[];
    routeCoordinates: Coords[];
    chargingStops: any;
    restingStops: any;
    summary: RouteSummary;
}

export interface RouteSummary {
    distance?: number;
    duration?: number;
    total_cost?: number;
    toll_cost?: number;
    total_consumption?: number;
    charging_duration?: number;
    co2_savings?: number;
}

export type RouteDetails = {
    id: string;
    summary?: RouteSummary;
    sections: RouteSection[];
    drivingCoordinates?: Coords[];
};

export const addDrivingCoordinates = (response: TripDetails): TripDetails => {
    const routesWithDriving = response.routes.map(
        (route): RouteDetails => ({
            ...route,
            drivingCoordinates: getDrivingCoordinates(route),
        })
    );
    const result = {
        ...response,
        routes: routesWithDriving,
    };
    return result;
};

export const getDrivingCoordinates = (routingResult: RouteDetails) => {
    const drivingCoordinates: Coords[] = routingResult.sections
        .filter((section) => section.sectionType === SectionType.Driving)
        .map((section) => section.info as DrivingSection)
        .flatMap((section) => section.coordinates)
        .map(mapToCoords);

    return drivingCoordinates;
};

export const mapToCoords = (coordinates: Coordinates) => {
    return { lat: coordinates.latitude, lng: coordinates.longitude };
};

import { Store } from '@reduxjs/toolkit';
import { trace } from '../../configuration/setup/trace';
import { DEFAULT_LOCALE, getSupportedLocale as defaultGetSupportedLocale } from './lang';
import { displayMessagesFetched, localeChanged } from './langSlice';
import getOr from 'lodash/fp/getOr';

// Webpack is weird sometimes, maybe it's Babel, who knows...
const normalizeDynamicImport = (imported: any) => getOr(imported, 'default', imported);

export const importDisplayMessages = (locale: string) =>
    import(`../../configuration/translations/${locale}.json`).then(normalizeDynamicImport).catch((error) => {
        trace.error(`Could not "../../configuration/translations/${locale}.json" file.`, error);
        return error;
    });

export const configureFetchDisplayMessages =
    (store: Store, fetchDisplayMessages = importDisplayMessages, getSupportedLocale = defaultGetSupportedLocale) =>
    async (locale: string) => {
        if (!locale) {
            trace.warn('No "locale" supplied when fetching display messages!');
            return Promise.reject();
        }

        store.dispatch(localeChanged(locale));
        const supportedLocale = getSupportedLocale(locale);

        try {
            const displayMessages = await fetchDisplayMessages(supportedLocale);
            trace.info(`Display messages fetched for "${supportedLocale}"`);
            store.dispatch(displayMessagesFetched({ locale: supportedLocale, displayMessages }));
        } catch (error: any) {
            trace.error(`Display messages for "${supportedLocale}" could not be fetched.`, error);
            store.dispatch(localeChanged(DEFAULT_LOCALE));
        }
    };

import { DndContext, MouseSensor, TouchSensor, UniqueIdentifier, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../configuration/setup/hooks';
import { fetchRoutes } from '../../features/fetchData/fetchRoutes';
import { selectCurrentStep } from '../../store/app/appSelectors';
import { NavigationStep } from '../../store/app/appSlice';
import { selectIsFetchRangeInProgress, selectIsFetchRoutesInProgress } from '../../store/route/routeSelectors';
import { selectOrderedWaypoints } from '../../store/search/searchSelectors';
import { Waypoint, waypointsOrderChanged } from '../../store/search/searchSlice';
import { showMapNotification } from '../../store/widget/actions/showMapNotification';
import { WaypointListItem } from './WaypointListItem';
import { WaypointsOptions } from './WaypointsOptions';

type OnSortEndProps = {
    oldIndex: number;
    newIndex: number;
};

const SortableWaypointContainer = () => {
    const dispatch = useAppDispatch();
    const intl = useIntl();

    const isLoadingRoutes = useAppSelector(selectIsFetchRoutesInProgress);
    const isLoadingRange = useAppSelector(selectIsFetchRangeInProgress);
    const orderedWaypoints = useAppSelector(selectOrderedWaypoints);
    const currentStep = useAppSelector(selectCurrentStep);
    const isSecondStep = currentStep === NavigationStep.StopsStep;

    const [, setActiveId] = useState<UniqueIdentifier | null>(null);

    const allowRemove = orderedWaypoints.length > 2;
    const totalWaypoints = orderedWaypoints.length - 1;

    const getIndex = (id: UniqueIdentifier) => orderedWaypoints.findIndex((wp) => wp.id === id);

    const sensors = useSensors(
        useSensor(MouseSensor, {
            // Require the mouse to move by 10 pixels before activating.
            // Slight distance prevents sortable logic messing with
            // interactive elements in the handler toolbar component.
            activationConstraint: {
                distance: 10,
            },
        }),
        useSensor(TouchSensor, {
            // Press delay of 250ms, with tolerance of 5px of movement.
            activationConstraint: {
                delay: 250,
                tolerance: 5,
            },
        })
    );

    const waypointOrderChanged = async (newWaypoints: Waypoint[]) => {
        dispatch(waypointsOrderChanged(newWaypoints));

        if (isSecondStep) {
            try {
                await dispatch(fetchRoutes);
            } catch (error) {
                const changeRouteSettings = intl.formatMessage({
                    id: 'intl-msg:smartRoute.notification.pleaseChangeRouteSettings',
                });
                const routeCalculationFail = intl.formatMessage({
                    id: 'intl-msg:smartRoute.notification.routeCalculationFailed',
                });
                showMapNotification('warning', changeRouteSettings, routeCalculationFail);
            }
        }
    };

    const onSortEnd = ({ oldIndex, newIndex }: OnSortEndProps) => {
        const newWaypoints = arrayMove(orderedWaypoints, oldIndex, newIndex);
        waypointOrderChanged(newWaypoints);
    };

    return (
        <div className="position-relative padding-top-25 display-flex align-items-center">
            {isSecondStep && isLoadingRoutes && (
                <div className="position-absolute top-0 right-0 margin-bottom-5">
                    <Spinner
                        text={
                            <FormattedMessage
                                id="intl-msg:smartRoute.tour.calculatingRoute"
                                data-testid="tour-spinner-title"
                            />
                        }
                    />
                </div>
            )}
            {isSecondStep && isLoadingRange && (
                <div className="position-absolute top-0 right-0 margin-bottom-5">
                    <Spinner
                        text={
                            <FormattedMessage
                                id="intl-msg:smartRoute.tour.computingRange"
                                data-testid="tour-spinner-title"
                            />
                        }
                    />
                </div>
            )}
            <div style={{ flexGrow: 1 }}>
                <DndContext
                    sensors={sensors}
                    autoScroll={false}
                    modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
                    onDragStart={({ active }) => {
                        if (active) {
                            setActiveId(active.id);
                        }
                    }}
                    onDragEnd={({ active, over }) => {
                        if (over && active.id !== over.id) {
                            onSortEnd({
                                oldIndex: getIndex(active.id),
                                newIndex: getIndex(over.id),
                            });
                        }
                        setActiveId(null);
                    }}
                    onDragCancel={() => setActiveId(null)}
                >
                    <SortableContext items={orderedWaypoints} strategy={verticalListSortingStrategy}>
                        <div>
                            {orderedWaypoints.map((waypoint: Waypoint, index: number) => (
                                <WaypointListItem
                                    key={waypoint.id}
                                    orderIndex={index}
                                    value={waypoint}
                                    allowRemove={allowRemove}
                                    totalWaypoints={totalWaypoints}
                                />
                            ))}
                        </div>
                    </SortableContext>
                </DndContext>
            </div>
        </div>
    );
};

export const SearchEditor = () => {
    return (
        <div className="RoutePlannerSearch" data-testid="RoutePlannerSearch">
            <SortableWaypointContainer />
            <WaypointsOptions />
        </div>
    );
};

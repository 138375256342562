import { facadeApi as api } from './baseApi';
const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        generateIsoline: build.mutation<GenerateIsolineApiResponse, GenerateIsolineApiArg>({
            query: (queryArg) => ({ url: '/v1/isoline', method: 'POST', body: queryArg.isolineRequest }),
        }),
        getAssetData: build.query<GetAssetDataApiResponse, GetAssetDataApiArg>({
            query: (queryArg) => ({ url: `/v1/asset-data/${queryArg.assetId}` }),
        }),
        generateRoute: build.mutation<GenerateRouteApiResponse, GenerateRouteApiArg>({
            query: (queryArg) => ({ url: '/v1/route', method: 'POST', body: queryArg.routeRequest }),
        }),
        getAccountSubscriptionInfo: build.query<
            GetAccountSubscriptionInfoApiResponse,
            GetAccountSubscriptionInfoApiArg
        >({
            query: () => ({ url: '/v1/subscription/account' }),
        }),
        getAssetSubscriptionState: build.query<GetAssetSubscriptionStateApiResponse, GetAssetSubscriptionStateApiArg>({
            query: (queryArg) => ({ url: `/v1/subscription/asset/${queryArg.assetId}` }),
        }),
        getPermittedAssets: build.query<GetPermittedAssetsApiResponse, GetPermittedAssetsApiArg>({
            query: () => ({ url: '/v1/permitted-assets' }),
        }),
        getAccountGroups: build.query<GetAccountGroupsApiResponse, GetAccountGroupsApiArg>({
            query: () => ({ url: '/v1/tags/groups' }),
        }),
        getOperatorList: build.query<GetOperatorListApiResponse, GetOperatorListApiArg>({
            query: () => ({ url: '/v1/charging-station/operators' }),
        }),
        getChargingStationList: build.query<GetChargingStationListApiResponse, GetChargingStationListApiArg>({
            query: (queryArg) => ({
                url: '/v1/charging-station/list',
                method: 'POST',
                body: queryArg.chargingStationListRequest,
            }),
        }),
        getChargingStation: build.query<GetChargingStationApiResponse, GetChargingStationApiArg>({
            query: (queryArg) => ({ url: `/v1/charging-station/${queryArg.id}` }),
        }),
        getNearestChargingStation: build.mutation<
            GetNearestChargingStationApiResponse,
            GetNearestChargingStationApiArg
        >({
            query: (queryArg) => ({
                url: '/v1/charging-station/nearest',
                method: 'POST',
                body: queryArg.nearestChargingStationRequest,
            }),
        }),
        getFeatureToggles: build.query<GetFeatureTogglesApiResponse, GetFeatureTogglesApiArg>({
            query: () => ({ url: '/v1/feature-toggles' }),
        }),
        getVehicleProfileList: build.query<GetVehicleProfileListApiResponse, GetVehicleProfileListApiArg>({
            query: () => ({ url: '/v1/vehicle-profiles' }),
        }),
        getVehicleProfileDetails: build.query<GetVehicleProfileDetailsApiResponse, GetVehicleProfileDetailsApiArg>({
            query: (queryArg) => ({ url: `/v1/vehicle-profiles/${queryArg.id}` }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as facadeApi };
export type GenerateIsolineApiResponse = /** status 200 OK */ IsolineResponse;
export type GenerateIsolineApiArg = {
    isolineRequest: IsolineRequest;
};
export type GetAssetDataApiResponse = /** status 200 OK */ AssetDataResponse;
export type GetAssetDataApiArg = {
    assetId: string;
};
export type GenerateRouteApiResponse = /** status 200 OK */ RouteResponse;
export type GenerateRouteApiArg = {
    routeRequest: RouteRequest;
};
export type GetAccountSubscriptionInfoApiResponse = /** status 200 OK */ AccountSubscriptionInfo;
export type GetAccountSubscriptionInfoApiArg = void;
export type GetAssetSubscriptionStateApiResponse =
    /** status 200 Returns current asset subscription state */ AssetSubscriptionState;
export type GetAssetSubscriptionStateApiArg = {
    assetId: string;
};
export type GetPermittedAssetsApiResponse = /** status 200 Returns permitted assets */ PermittedAssetsResponse;
export type GetPermittedAssetsApiArg = void;
export type GetAccountGroupsApiResponse = /** status 200 OK */ AccountGroups;
export type GetAccountGroupsApiArg = void;
export type GetOperatorListApiResponse = /** status 200 Successful response */ OperatorListResponse;
export type GetOperatorListApiArg = void;
export type GetChargingStationListApiResponse = /** status 200 Successful response */ ChargingStationListResponse;
export type GetChargingStationListApiArg = {
    chargingStationListRequest: ChargingStationListRequest;
};
export type GetChargingStationApiResponse = /** status 200 Successful response */ ChargingStation;
export type GetChargingStationApiArg = {
    /** ID of charging station */
    id: string;
};
export type GetNearestChargingStationApiResponse = /** status 200 Successful response */ ChargingStationListResponse;
export type GetNearestChargingStationApiArg = {
    nearestChargingStationRequest: NearestChargingStationRequest;
};
export type GetFeatureTogglesApiResponse = /** status 200 Returns feature toggles */ FeatureToggles;
export type GetFeatureTogglesApiArg = void;
export type GetVehicleProfileListApiResponse =
    /** status 200 Returns a list of all available vehicle profiles */ VehicleProfileListItem[];
export type GetVehicleProfileListApiArg = void;
export type GetVehicleProfileDetailsApiResponse = /** status 200 Returns a vehicle profile */ VehicleProfileDetails;
export type GetVehicleProfileDetailsApiArg = {
    /** Vehicle profile id */
    id: string;
};
export type Coordinates = {
    latitude: number;
    longitude: number;
};
export type IsolineItem = {
    points?: Coordinates[];
};
export type IsolineResponse = {
    error_code?: Error_code;
    items?: IsolineItem[];
};
export type IsolineRequest = {
    /** UUID which represents the asset */
    asset_id: string;
    origin: Coordinates;
    /** Request isoline type */
    isolineMode: IsolineMode;
    /** Maximum values per isoline request type (should be positive)
    DISTANCE: 1000000 (in meters)
    TIME: 32400 (in seconds)
    CONSUMPTION: EV (228060 in Watt-hour)
     */
    amount: number;
    /** Vehicle total weight in Kg (e.g. 30000) */
    weight?: number;
};
export type Position = {
    latitude?: number;
    longitude?: number;
    /** Height above sea level in meters */
    altitude?: number;
    /** Speed of the device in Km/h */
    speed?: number;
};
export type State = {
    /** Mileage in Km */
    mileage?: number;
    ignition_on?: boolean;
    /** unit in % */
    state_of_charge?: number;
    /** Remaining range of electric vehicles in Km */
    remaining_range?: number;
    /** Energy level in kWh */
    energy_level?: number;
    /** Fuel level in % */
    fuel_level?: number;
    /** Vehicle total weight in Kg */
    weight_total?: number;
};
export type AssetDetails = {
    /** Possible values - diesel, hydrogen, gas or electric */
    fuel_type?: string;
    number_of_axes?: number;
    /** Information collected about the vehicles in a non defined format */
    tires?: string;
    /** Manually calculated field (given the presence of electric related events) */
    details_bev?: boolean;
};
export type DrivingAndRestingDetails = {
    /** Max remaining time until the next required break (in seconds) */
    remaining_current_driving_time?: number;
};
export type DriverState = {
    driving_and_resting_details?: DrivingAndRestingDetails;
};
export type TemperatureSummary = {
    /** temperature in Celsius */
    temperature?: number;
    /** Temperature description */
    temperature_description?: string;
    /** Highest temperature for the day in Celsius */
    high_temperature?: string;
    /** Lowest temperature for the day in Celsius */
    low_temperature?: string;
};
export type WindSummary = {
    /** Air description */
    air_description?: string;
    /** Wind speed in km/h */
    wind_speed?: number;
    /** Direction of the wind - North */
    wind_description?: string;
    /** Abbreviation of the direction of the wind - N */
    wind_short_description?: string;
};
export type WeatherSummary = {
    /** Weather description */
    description?: string;
    /** Sky weather description */
    sky_description?: string;
    humidity?: string;
    temperature_summary?: TemperatureSummary;
    wind_summary?: WindSummary;
};
export type AssetData = {
    /** UUID which represents the asset */
    asset_id: string;
    /** UUID which represents the account */
    account_id: string;
    /** name of the asset */
    name?: string;
    /** asset type (e.g truck, bus, van or trailer) */
    vehicleType?: VehicleType;
    occurred_at?: string;
    /** Outside temperate provided by HERE weather request */
    temperature?: number;
    position?: Position;
    state?: State;
    details?: AssetDetails;
    driver_state?: DriverState;
    weather?: WeatherSummary;
};
export type AssetDataResponse = {
    asset_data?: AssetData;
};
export type RouteOverview = {
    /** UUID which represents the fastest route */
    fastest_route?: string;
    /** UUID which represents the cheapest route */
    cheapest_route?: string;
    /** UUID which represents the shortest route */
    shortest_route?: string;
};
export type RouteSummary = {
    /** Route distance in meters */
    distance?: number;
    /** Route duration in seconds */
    duration?: number;
    /** Total vehicle's cost on a route (cost per km) in the chosen currency */
    vehicle_cost?: number;
    /** Total energy's cost on a route (cost per kwh) in the chosen currency */
    energy_cost?: number;
    /** Total cost of the route (tolls and cost/km) in the chosen currency */
    total_cost?: number;
    /** Toll cost in the chosen currency */
    toll_cost?: number;
    /** (NOT USED) Fuel Cost in (to be determined) */
    fuel_cost?: number;
    /** Total Consumption in KwH */
    total_consumption?: number;
    /** Charging duration in minutes */
    charging_duration?: number;
    /** CO2 Savings in Kg */
    co2_savings?: number;
    /** (NOT USED) CO2 Emissions in (to be determined) */
    co2_emissions?: number;
};
export type WeatherInfo = {
    /** description of sky conditions */
    sky_info: string;
    /** temperature in Celsius */
    temperature: number;
    /** wind speed in km/h */
    wind_speed: number;
    /** description of the wind direction */
    wind_direction: string;
};
export type DepartureSection = {
    location: Coordinates;
    departure_time: string;
    address: string;
    soc_percentage?: number;
    slow_traffic: boolean;
    weather_info: WeatherInfo;
};
export type Operator = {
    id?: string;
    name?: string;
};
export type ConnectorRate = {
    /** Connector price in EUR. */
    price?: string;
    contractType?: string;
};
export type Connector = {
    id?: string;
    msp_connector_id?: string;
    evse_id?: string;
    status?: string;
    standard?: string;
    plugType?: string;
    power_type?: string;
    /** The max power that could be transmitted through this connector in kW. */
    max_power?: number;
    truck_charging_grade?: number;
    msp_price_status?: string;
    prices?: ConnectorRate[];
};
export type ChargingStation = {
    id: string;
    operator: Operator;
    name: string;
    address?: string;
    city?: string;
    postal_code?: string;
    country_code?: string;
    location: Coordinates;
    /** The max power that could be transmitted through one of the connectors in kW. */
    max_power?: number;
    truck_charging_grade?: number;
    support_phone?: string;
    connectors?: Connector[];
};
export type DrivingSection = {
    coordinates: Coordinates[];
    estimated_departure: string;
    estimated_duration: number;
    distance: number;
    nearChargingStations?: ChargingStation[];
};
export type ChargingStationSection = {
    charging_station: ChargingStation;
    estimated_arrival_time: string;
    /** Charging duration in minutes */
    duration?: number;
};
export type AdditionalStopSection = {
    location: Coordinates;
    estimated_arrival_time: string;
    address: string;
    duration: number;
};
export type ArrivalSection = {
    location: Coordinates;
    estimated_arrival_time: string;
    address: string;
};
export type RouteSection = {
    sectionType: SectionType;
    info: DepartureSection | DrivingSection | ChargingStationSection | AdditionalStopSection | ArrivalSection;
};
export type Route = {
    id: string;
    summary?: RouteSummary;
    sections: RouteSection[];
};
export type RouteResponse = {
    /** UUID which represents the asset */
    asset_id?: string;
    timestamp: string;
    overview: RouteOverview;
    routes: Route[];
};
export type AdditionalStopWaypoint = {
    type: string;
    location: Coordinates;
};
export type ChargingStationWaypoint = {
    type: string;
    location: Coordinates;
    id: string;
};
export type Waypoint =
    | ({
          type: 'ADDITIONAL_STOP';
      } & AdditionalStopWaypoint)
    | ({
          type: 'CHARGING_STATION';
      } & ChargingStationWaypoint);
export type RoutingOptions = {
    departure_time?: string;
    avoid_toll_roads?: boolean;
    avoid_highways?: boolean;
    avoid_ferry?: boolean;
    avoid_tunnel?: boolean;
    avoid_difficult_turns?: boolean;
    avoid_route_alternatives?: boolean;
    /** Cost per kilometer of the vehicle in the specified currency */
    cost_per_km?: number;
    /** Cost per kilowatt/hour of the vehicle in the specified currency */
    cost_per_kwh?: number;
    currency?: Currency;
};
export type VehicleOptions = {
    /** Max vehicle weight in kg (including trailers and goods) */
    gross_weight?: number;
    /** Vehicle height in m */
    height?: number;
    /** Vehicle width in m */
    width?: number;
    /** Vehicle length in m */
    length?: number;
    /** The vehicle axle count of the truck plus its trailer(s) */
    vehicle_axles_count?: number;
    trailer_axles_count?: number;
    trailer_count?: number;
    /** Maximal weight per axel in kg */
    weight_per_axle?: number;
    hazard_classification?: Hazard_classification;
    emission_classification?: Emission_classification;
    /** Expected vehicle payload weight in kg */
    vehicle_payload_weight?: number;
    /** Average expected outdoor temperature in Celsius */
    average_outdoor_temperature?: number;
    vehicle_type?: Vehicle_type;
    /** Vehicle profile id */
    profile_id?: string;
};
export type ChargingOptions = {
    /** Min SoC on charging */
    min_soc_percentage?: number;
    /** Max SoC on charging */
    max_soc_percentage?: number;
    /** SoC at destination percentage */
    soc_at_destination?: number;
    /** SoC at origin percentage */
    soc_at_origin?: number;
    /** Placeholder plug types https://developer.here.com/documentation/charging-stations/dev_guide/topics/resource-type-connector-types.html */
    vehicle_plug_type?: ('CCS' | 'MCS')[];
    charging_station_grading?: ('TRUCK_READY' | 'LIMITED' | 'UNSPECIFIED')[];
    /** List of Operator Ids (filtering purposes) */
    charging_station_operator?: string[];
    payment_options?: string[];
    /** Show only eMSP related Charging Stations */
    eMSPOnly?: boolean;
};
export type RouteRequest = {
    asset_id?: string;
    origin: Coordinates;
    destination: Coordinates;
    waypoints: Waypoint[];
    routing_options: RoutingOptions;
    vehicle_options: VehicleOptions;
    charging_options: ChargingOptions;
};
export type AccountSubscriptionInfo = {
    /** true when at least one asset has the product booked */
    subscribed: boolean;
};
export type AssetSubscriptionState = {
    /** Actual subscription status */
    status: Status;
    /** Subscription level */
    subscription?: Subscription;
};
export type ErrorMessage = {
    /** Error code */
    error_code: Error_code2;
    /** Additional details about the error */
    message?: string;
};
export type PermittedAsset = {
    id: string;
    name: string;
    type: string;
    identification?: string;
    identification_type?: string;
    subscription_state: AssetSubscriptionState;
    group_ids: string[];
};
export type PermittedAssetsResponse = {
    items: PermittedAsset[];
};
export type GroupAccountInfo = {
    id: string;
    name: string;
};
export type AccountGroups = {
    items: GroupAccountInfo[];
};
export type OperatorListResponse = {
    items: Operator[];
};
export type ChargingStationListResponse = {
    paginationToken?: string;
    items: ChargingStation[];
};
export type BoundingBox = {
    minLon: number;
    minLat: number;
    maxLon: number;
    maxLat: number;
};
export type ChargingStationListRequest = {
    /** Token for cursor pagination */
    paginationToken?: string;
    /** Number of items per page (min = 1, max = 1000) */
    limit?: number;
    /** Charging Station power to query */
    chargingPower?: ChargingPower[];
    /** List of charging grades to query (default = 2,4) */
    truckChargingGrades?: number[];
    /** List of charging station ids to query */
    ids?: string[];
    /** Show only eMSP related Charging Stations */
    eMSPOnly?: boolean;
    bbox?: BoundingBox;
};
export type NearestChargingStationRequest = {
    coordinates?: Coordinates[];
    /** Radius search in meters (m). */
    radius?: number;
    /** number of desired items returned */
    limit?: number;
    /** Show only eMSP related Charging Stations */
    eMSPOnly?: boolean;
};
export type FeatureToggles = {
    [key: string]: boolean | string | number;
};
export type VehicleProfileListItem = {
    id: string;
    profileName: string;
    engineType?: EngineType;
};
export type VehicleProfilePackWeight = {
    packs: number;
    weight: number;
};
export type VehicleProfileDetails = {
    id: string;
    profileName: string;
    vehicleType: VehicleType2;
    engineType: EngineType;
    length: number;
    width: number;
    height: number;
    emptyWeightVehicle: VehicleProfilePackWeight[];
    emptyWeightFreightTrain: VehicleProfilePackWeight[];
    maxFreightTrainWeight: number;
    maxCargoWeight: VehicleProfilePackWeight[];
    maxWeightPerAxle: number;
    maxWeightPerAxleGroup?: number;
    axlesVehicle: number;
    axlesTrailer?: number;
    numberAttachedTrailers: number;
    defaultInstalledBatteryPackCount: number;
    batteryCapacityPerPack: number;
    plugTypes: ('CCS' | 'MCS')[];
};
export enum Error_code {
    UnsupportedType = 'UNSUPPORTED_TYPE',
    CommunicationFailure = 'COMMUNICATION_FAILURE',
}
export enum IsolineMode {
    Distance = 'DISTANCE',
    Time = 'TIME',
    Consumption = 'CONSUMPTION',
}
export enum VehicleType {
    Truck = 'TRUCK',
    Bus = 'BUS',
    Van = 'VAN',
    Trailer = 'TRAILER',
    // eslint-disable-next-line id-denylist
    Undefined = 'UNDEFINED',
}
export enum SectionType {
    Departure = 'DEPARTURE',
    Driving = 'DRIVING',
    ChargingStation = 'CHARGING_STATION',
    AdditionalStop = 'ADDITIONAL_STOP',
    Arrival = 'ARRIVAL',
}
export enum Currency {
    Eur = 'EUR',
    Bam = 'BAM',
    Bgn = 'BGN',
    Brl = 'BRL',
    Chf = 'CHF',
    Czk = 'CZK',
    Dkk = 'DKK',
    Gbp = 'GBP',
    Huf = 'HUF',
    Hrk = 'HRK',
    Mkd = 'MKD',
    Nok = 'NOK',
    Pln = 'PLN',
    Ron = 'RON',
}
export enum Hazard_classification {
    Explosive = 'explosive',
    Gas = 'gas',
    Flammable = 'flammable',
    Combustible = 'combustible',
    Organic = 'organic',
    Poison = 'poison',
    Radioactive = 'radioactive',
    Corrosive = 'corrosive',
    PoisonousInhalation = 'poisonousInhalation',
    HarmfulToWater = 'harmfulToWater',
    Other = 'other',
}
export enum Emission_classification {
    EuroI = 'EURO_I',
    EuroIi = 'EURO_II',
    EuroIii = 'EURO_III',
    EuroIv = 'EURO_IV',
    EuroV = 'EURO_V',
    EuroVi = 'EURO_VI',
    EuroEev = 'EURO_EEV',
}
export enum Vehicle_type {
    StraightTruck = 'STRAIGHT_TRUCK',
    Tractor = 'TRACTOR',
}
export enum Status {
    Active = 'ACTIVE',
    PendingActivation = 'PENDING_ACTIVATION',
    PendingCancellation = 'PENDING_CANCELLATION',
    Inactive = 'INACTIVE',
}
export enum Subscription {
    SrpL10 = 'SRP_L10',
    SrpL10Ft = 'SRP_L10_FT',
    SrpL11 = 'SRP_L11',
    SrpL12 = 'SRP_L12',
    NoLevel = 'NO_LEVEL',
}
export enum Error_code2 {
    InternalFailure = 'INTERNAL_FAILURE',
    NotFound = 'NOT_FOUND',
    InvalidAssetId = 'INVALID_ASSET_ID',
    NoSubscriptionFound = 'NO_SUBSCRIPTION_FOUND',
}
export enum ChargingPower {
    Low = 'LOW',
    Medium = 'MEDIUM',
    High = 'HIGH',
}
export enum EngineType {
    Bev = 'BEV',
    Ice = 'ICE',
}
export enum VehicleType2 {
    StraightTruck = 'STRAIGHT_TRUCK',
    Tractor = 'TRACTOR',
}
export const {
    useGenerateIsolineMutation,
    useGetAssetDataQuery,
    useLazyGetAssetDataQuery,
    useGenerateRouteMutation,
    useGetAccountSubscriptionInfoQuery,
    useLazyGetAccountSubscriptionInfoQuery,
    useGetAssetSubscriptionStateQuery,
    useLazyGetAssetSubscriptionStateQuery,
    useGetPermittedAssetsQuery,
    useLazyGetPermittedAssetsQuery,
    useGetAccountGroupsQuery,
    useLazyGetAccountGroupsQuery,
    useGetOperatorListQuery,
    useLazyGetOperatorListQuery,
    useGetChargingStationListQuery,
    useLazyGetChargingStationListQuery,
    useGetChargingStationQuery,
    useLazyGetChargingStationQuery,
    useGetNearestChargingStationMutation,
    useGetFeatureTogglesQuery,
    useLazyGetFeatureTogglesQuery,
    useGetVehicleProfileListQuery,
    useLazyGetVehicleProfileListQuery,
    useGetVehicleProfileDetailsQuery,
    useLazyGetVehicleProfileDetailsQuery,
} = injectedRtkApi;

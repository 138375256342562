import { http, HttpResponse } from 'msw';
import { API } from '../../../src/common/constants';
import { config } from '../../../src/configuration/config';

// eslint-disable-next-line max-len
const URL = `${config.backend.BACKEND_SERVICE}/${API.serviceName}/${API.version.latest}${API.endpoints.chargeStationNearAddress}`;

export default http.post(URL, () => {
    return HttpResponse.json({
        items: [
            chargePointData1,
            chargePointData2,
            chargePointData3,
            chargePointData32,
            chargePointData33,
            chargePointData34,
            chargePointData35,
            chargePointData36,
        ],
    });
});

export const chargePointData1 = {
    id: '07765f10-d848-40eb-953f-e81878bd34c9',
    operator: {
        id: '2aefcdfb-b632-420b-85af-c5c07b3e6322',
        name: 'BP Europa SE',
    },
    name: 'DE | Aral 0F150 | Bahnhofstraße 53, 55494 Rheinböllen | TRUCK - DC #02',
    address: 'Bahnhofstraße 53',
    city: 'Rheinböllen',
    postal_code: '55494',
    country_code: 'DE',
    location: {
        latitude: 49.996284,
        longitude: 7.683968,
    },
    max_power: 300,
    truck_charging_grade: 4,
    support_phone: '+49 800 135 351 1',
    connectors: [
        {
            id: '797633c4-6f94-4415-a84b-534f814574ae',
            msp_connector_id: '',
            evse_id: 'DE*BPE*E0F150*02*06',
            status: 'UNKNOWN',
            standard: 'IEC_62196_T2_COMBO',
            plugType: 'CCS Combo 2 Plug (Cable Attached)',
            power_type: '',
            max_power: 300,
            truck_charging_grade: 4,
            msp_price_status: '',
            prices: [],
        },
        {
            id: '640ee297-460e-4586-9c73-2e46b979132e',
            msp_connector_id: '',
            evse_id: 'DE*BPE*E0F150*02*04',
            status: 'UNKNOWN',
            standard: 'IEC_62196_T2_COMBO',
            plugType: 'CCS Combo 2 Plug (Cable Attached)',
            power_type: '',
            max_power: 300,
            truck_charging_grade: 4,
            msp_price_status: '',
            prices: [],
        },
    ],
};

export const chargePointData2 = {
    id: '430c2be9-b6ea-44fc-bd4b-fceadb204b39',
    operator: {
        id: '83f23926-43ae-4e43-8b3c-d2ad60cc1fb8',
        name: 'Proviridis/3',
    },
    name: 'Proviridis/6426d2f8e68d7362559e5b6f',
    address: 'Impasse des Bois de Tharlet',
    city: 'Viriat',
    postal_code: '01440',
    country_code: 'FR',
    location: {
        latitude: 46.228058,
        longitude: 5.259945,
    },
    max_power: 300,
    truck_charging_grade: 4,
    support_phone: '',
    connectors: [
        {
            id: 'c509ceba-8d80-4701-8587-d5a6fd650eb9',
            msp_connector_id: '',
            evse_id: 'FR*PVD*EVG*BEB01*AD01*3',
            status: 'UNKNOWN',
            standard: 'IEC_62196_T2_COMBO',
            plugType: 'CCS Combo 2 Plug (Cable Attached)',
            power_type: '',
            max_power: 450,
            truck_charging_grade: 4,
            msp_price_status: '',
            prices: [],
        },
        {
            id: '95582f12-dc3b-46b5-a56a-46f670f2a130',
            msp_connector_id: '',
            evse_id: 'FR*PVD*EVG*BEB01*AD02*1',
            status: 'UNKNOWN',
            standard: 'IEC_62196_T2_COMBO',
            plugType: 'CCS Combo 2 Plug (Cable Attached)',
            power_type: '',
            max_power: 350,
            truck_charging_grade: 4,
            msp_price_status: '',
            prices: [],
        },
        {
            id: '87d9803d-f974-4241-be13-7ad5dc870bcf',
            msp_connector_id: '',
            evse_id: 'FR*PVD*EVG*BEB01*AD02*3',
            status: 'UNKNOWN',
            standard: 'IEC_62196_T2_COMBO',
            plugType: 'CCS Combo 2 Plug (Cable Attached)',
            power_type: '',
            max_power: 400,
            truck_charging_grade: 4,
            msp_price_status: '',
            prices: [],
        },
        {
            id: '296a436d-0762-4f4f-9ac9-4cd25d2c4e0f',
            msp_connector_id: '',
            evse_id: 'FR*PVD*EVG*BEB01*AD01*1',
            status: 'UNKNOWN',
            standard: 'IEC_62196_T2_COMBO',
            plugType: 'CCS Combo 2 Plug (Cable Attached)',
            power_type: '',
            max_power: 450,
            truck_charging_grade: 4,
            msp_price_status: '',
            prices: [],
        },
        {
            id: 'f81d4614-1bc6-4cc5-a150-969c8e05e496',
            msp_connector_id: '',
            evse_id: 'FR*PVD*EVG*BEB01*AD03*3',
            status: 'UNKNOWN',
            standard: 'IEC_62196_T2_COMBO',
            plugType: 'CCS Combo 2 Plug (Cable Attached)',
            power_type: '',
            max_power: undefined,
            truck_charging_grade: 4,
            msp_price_status: '',
            prices: [],
        },
        {
            id: '7a1b1f0d-a3a2-4db3-8a00-b1d923e72b46',
            msp_connector_id: '',
            evse_id: 'FR*PVD*EVG*BEB01*AD03*1',
            status: 'UNKNOWN',
            standard: 'IEC_62196_T2_COMBO',
            plugType: 'CCS Combo 2 Plug (Cable Attached)',
            power_type: '',
            max_power: 300,
            truck_charging_grade: 4,
            msp_price_status: '',
            prices: [],
        },
    ],
};

export const chargePointData3 = {
    id: '430c2be9-b6ea-44fc-bd4b-fceadb204b31',
    operator: {
        id: '83f23926-43ae-4e43-8b3c-d2ad60cc1fb8',
        name: 'Proviridis',
    },
    name: 'Proviridis',
    address: 'Impasse des Bois de Tharlet',
    city: 'Viriat',
    postal_code: '01440',
    country_code: 'FR',
    location: {
        latitude: 46.228058,
        longitude: 5.259945,
    },
    max_power: 300,
    truck_charging_grade: 4,
    support_phone: '',
    connectors: [],
};

export const chargePointData32 = {
    id: '430c2be9-b6ea-44fc-bd4b-fceadb204b32',
    name: 'Proviridis2',
    address: 'Impasse des Bois de Tharlet 2',
    location: {
        latitude: 46.228058,
        longitude: 5.259945,
    },
};

export const chargePointData33 = {
    id: '430c2be9-b6ea-44fc-bd4b-fceadb204b33',
    name: 'Proviridis3',
    address: 'Impasse des Bois de Tharlet 3',
    location: {
        latitude: 46.228058,
        longitude: 5.259945,
    },
};

export const chargePointData34 = {
    id: '430c2be9-b6ea-44fc-bd4b-fceadb204b34',
    name: 'Proviridis4',
    address: 'Impasse des Bois de Tharlet 4',
    location: {
        latitude: 46.228058,
        longitude: 5.259945,
    },
};

export const chargePointData35 = {
    id: '430c2be9-b6ea-44fc-bd4b-fceadb204b35',
    name: 'Proviridis5',
    address: 'Impasse des Bois de Tharlet 5',
    location: {
        latitude: 46.228058,
        longitude: 5.259945,
    },
};

export const chargePointData36 = {
    id: '430c2be9-b6ea-44fc-bd4b-fceadb204b36',
    name: 'Proviridis6',
    address: 'Impasse des Bois de Tharlet 6',
    location: {
        latitude: 46.228058,
        longitude: 5.259945,
    },
};

import { HttpResponse, delay, http } from 'msw';
import { SmartRouteWebApiService } from '../../../src/common/constants';
import { config } from '../../../src/configuration/config';
import { generateRouteMock } from '../data/routeResponse';

// eslint-disable-next-line max-len
const URL = `${config.backend.BACKEND_SERVICE}/${SmartRouteWebApiService.serviceName}/${SmartRouteWebApiService.apiVersion.latest}${SmartRouteWebApiService.endpoints.route}`;

export default http.post(URL, async () => {
    await delay();
    const route = generateRouteMock();
    return HttpResponse.json(route);
});

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { STORE_BASE_PATH } from '../../common/constants';
import { defaultVehicleProfile } from '../vehicleProfile/defaultVehicleProfile';

interface StateOfCharge {
    minSoC: number;
    maxSoC: number;
    originSoC: number;
    destinationSoC: number;
}

interface ChargingPower {
    lowPower: boolean;
    midPower: boolean;
    highPower: boolean;
}

interface ChargeGrading {
    truckReady: boolean;
    limited: boolean;
    unspecified: boolean;
}

interface PlugType {
    css: boolean;
    mcs: boolean;
}

export interface ChargingState {
    stateOfCharge: StateOfCharge;
    chargingPower: ChargingPower;
    chargingOptions: {
        grading: ChargeGrading;
        plugType: PlugType;
        operator: string[];
        payment: string[];
    };
}

const initialState: ChargingState = {
    stateOfCharge: {
        minSoC: defaultVehicleProfile.minSoC,
        maxSoC: defaultVehicleProfile.maxSoC,
        originSoC: defaultVehicleProfile.originSoC,
        destinationSoC: defaultVehicleProfile.destinationSoC,
    },
    chargingPower: {
        lowPower: false,
        midPower: true,
        highPower: false,
    },
    chargingOptions: {
        grading: {
            truckReady: true,
            limited: true,
            unspecified: false,
        },
        plugType: {
            css: true,
            mcs: false,
        },
        operator: [],
        payment: [],
    },
};

export const chargingSlice = createSlice({
    name: STORE_BASE_PATH + 'charging',
    initialState,
    reducers: {
        updateStateOfCharge: (state: ChargingState, action: PayloadAction<StateOfCharge>) => {
            state.stateOfCharge = action.payload;
        },
        updateChargingPower: (state: ChargingState, action: PayloadAction<ChargingPower>) => {
            state.chargingPower = action.payload;
        },
        updateChargingOptionsGrading: (state: ChargingState, action: PayloadAction<ChargeGrading>) => {
            state.chargingOptions.grading = action.payload;
        },
        updateChargingOptionsPlugType: (state: ChargingState, action: PayloadAction<PlugType>) => {
            state.chargingOptions.plugType = action.payload;
        },
        updateChargingOptionsOperator: (state: ChargingState, action: PayloadAction<string[]>) => {
            state.chargingOptions.operator = action.payload;
        },
        updateChargingOptionsPayment: (state: ChargingState, action: PayloadAction<string[]>) => {
            state.chargingOptions.payment = action.payload;
        },
    },
});

export const {
    updateStateOfCharge,
    updateChargingPower,
    updateChargingOptionsGrading,
    updateChargingOptionsPlugType,
    updateChargingOptionsOperator,
    updateChargingOptionsPayment,
} = chargingSlice.actions;

export default chargingSlice.reducer;

import { sendMessage } from '../widgetServices';
import { widgetActions } from '../widgetSlice';

export type EventShowNotification = {
    type: 'info' | 'warning' | 'error' | 'success';
    title?: string;
    message: string;
};

export const showMapNotification = (
    type: 'info' | 'warning' | 'error' | 'success',
    message: string,
    title?: string
) => {
    sendMessage(widgetActions.showMapNotification({ type, message, title }));
};

import isNil from 'lodash/fp/isNil';
import { useEffect, useState } from 'react';
import { Flags, FlagsProvider } from 'react-feature-flags';
import { FormattedMessage } from 'react-intl';
import { FeatureFlag, ToggleFlag, buildFeatureFlagsStructure } from '../../../test/utils/utilsFeatureFlags';
import { FEATURE_TOGGLES_POLLING_FREQ } from '../../common/constants';
import { LabeledNumberInput } from '../../components/LabeledNumberInput/LabeledNumberInput';
import { LabeledSelect } from '../../components/LabeledSelect/LabeledSelect';
import { useAppDispatch, useAppSelector } from '../../configuration/setup/hooks';
import { trace } from '../../configuration/setup/trace';
import { useGetFeatureTogglesQuery, useGetVehicleProfileListQuery } from '../../store/facade/facadeApi';
import { selectOutdoorTemperature, selectTunnelCategory } from '../../store/vehicleProfile/vehicleProfileSelectors';
import { changeAverageOutdoorTemperature, changeTunnelCategory } from '../../store/vehicleProfile/vehicleProfileSlice';
import {
    HEREVehicleAttibuteNames,
    TunnelCategory,
    VEHCILE_ATTRIBUTES_UNITS,
} from '../../store/vehicleProfile/vehicleProfileTypes';

export const TUNNEL_ALL_OPTION = 'All';
const TunnelCategoryOptions = [
    { id: TUNNEL_ALL_OPTION, label: TUNNEL_ALL_OPTION },
    { id: TunnelCategory.B, label: TunnelCategory.B },
    { id: TunnelCategory.C, label: TunnelCategory.C },
    { id: TunnelCategory.D, label: TunnelCategory.D },
    { id: TunnelCategory.E, label: TunnelCategory.E },
];

export type TunnelCategoryTypes =
    | typeof TUNNEL_ALL_OPTION
    | TunnelCategory.B
    | TunnelCategory.C
    | TunnelCategory.D
    | TunnelCategory.E;

export const AdditionalRoutingOptions = () => {
    const dispatch = useAppDispatch();

    const { data, isError, isLoading } = useGetFeatureTogglesQuery(undefined, {
        pollingInterval: FEATURE_TOGGLES_POLLING_FREQ * 1000,
    });
    const [featureFlags, setFeatureFlags] = useState<FeatureFlag[]>();
    useGetVehicleProfileListQuery();

    useEffect(() => {
        if (data) {
            setFeatureFlags(buildFeatureFlagsStructure(data as ToggleFlag));
            trace.info('Fetched account feature toogles');
        }
        if (isError || (!isLoading && !data)) {
            throw new Error('Was not able to fetch the account feature toogles');
        }
    }, [data, isError, isLoading]);

    const tunnelCategory = useAppSelector(selectTunnelCategory);
    const outdoorTemperature = useAppSelector(selectOutdoorTemperature);

    return (
        !isNil(featureFlags) && (
            <FlagsProvider value={featureFlags}>
                <div className="display-flex justify-content-between gap-10" data-testid="aditional-routing-options">
                    <LabeledSelect
                        className="flex-1-1-0"
                        label={<FormattedMessage id="intl-msg:smartRoute.vehicle.tunnelCategory" />}
                        optionLabels={TunnelCategoryOptions}
                        selectedOption={tunnelCategory}
                        onChange={(value) => dispatch(changeTunnelCategory(value))}
                    />
                    <Flags authorizedFlags={['vehicleOutdoorTemperature']}>
                        <LabeledNumberInput
                            integerOnly
                            className="flex-1-1-0"
                            labelId="intl-msg:smartRoute.vehicle.outdoorTemperature"
                            value={outdoorTemperature}
                            unit={VEHCILE_ATTRIBUTES_UNITS[HEREVehicleAttibuteNames.OUTDOOR_TEMPERATURE]}
                            onChange={(value) => dispatch(changeAverageOutdoorTemperature(value))}
                        />
                    </Flags>
                </div>
            </FlagsProvider>
        )
    );
};

import { Unit } from '../../common/constants';
import { VehicleProfileDetails } from '../facade/facadeApi';

export type VehicleTypes = VehicleProfileDetails['vehicleType'];

export enum VehicleType {
    TRUCK_AND_TRAILER_40T = 'Truck & Trailer 40t',
    TRUCK_11T = 'Truck 11t',
    TRUCK_7T5 = 'Truck 7.5t',
    TRANSPORTER_3T5 = 'Transporter 3.5t',
    CAR = 'Car',
    CUSTOM_EV = 'Customized Electric Vehicle',
    CUSTOM_ICE = 'Customized Combustion Vehicle',
}

export enum TollVehicleType {
    CAR = 2,
    TRUCK = 3,
}

export enum EmissionClass {
    NONE = '',
    EURO_I = 'EURO_I',
    EURO_II = 'EURO_II',
    EURO_III = 'EURO_III',
    EURO_IV = 'EURO_IV',
    EURO_V = 'EURO_V',
    EURO_VI = 'EURO_VI',
    EURO_EEV = 'EURO_EEV',
    ELECTRIC_VEHICLE = 'ELECTRIC_VEHICLE',
}

export enum HazardClass {
    NONE = 'none',
    EXPLOSIVE = 'explosive',
    GAS = 'gas',
    FLAMMABLE = 'flammable',
    COMBUSTIBLE = 'combustible',
    ORGANIC = 'organic',
    POISON = 'poison',
    RADIOACTIVE = 'radioActive',
    CORROSIVE = 'corrosive',
    INHALATION_HAZARD = 'poisonousInhalation',
    HARMFUL_TO_WATER = 'harmfulToWater',
    OTHER = 'other',
}

export enum TunnelCategory {
    B = 'B',
    C = 'C',
    D = 'D',
    E = 'E',
}

export enum Optimization {
    FAST = 'fastest',
    SHORT = 'shortest',
}

export enum PlugType {
    Type1 = 'Type1',
    Type2 = 'Type2',
}

export enum ChargingSpeed {
    HPC = 'HPC',
    AC = 'AC',
    DC = 'DC',
}

export enum PaymentMethod {
    Manually = 'manual',
    PlugAndCharge = 'plug&charge',
}

export enum PaymentOption {
    APPLE_PAY = 'applePay',
    GOOGLE_PAY = 'googlePay',
    PAY_PAL = 'payPal',
    CREDIT_CARD = 'creditCard',
    DEBIT_CARD = 'debitCard',
    CHARGING_CARD = 'chargingCard',
    PLUG_CHARGE = 'plug&Charge',
    MAN_MSP = 'MANeMSP',
    OTHER = 'other',
}

export enum HEREVehicleAttibuteNames {
    TOLL_VEHICLE_TYPE = 'tollVehicleType',
    HEIGHT = 'height',
    LENGTH = 'length',
    WIDTH = 'width',
    PAYLOAD = 'payload',
    TOTAL_WEIGHT = 'totalWeight',
    EMPTY_WEIGHT = 'emptyWeight',
    CARGO_WEIGHT = 'cargoWeight',
    OUTDOOR_TEMPERATURE = 'outdoorTemperature',
    LIMITED_WEIGHT = 'limitedWeight',
    WEIGHT_PER_AXLE = 'weightPerAxle',
    WEIGHT_PER_AXLE_GROUP = 'weightPerAxleGroup',
    VEHCILE_NUMBER_AXLES = 'vehicleNumberAxles',
    TRAILER_NUMBER_AXLES = 'trailerNumberAxles',
    EMISSION_TYPE = 'emissionType',
    SHIPPED_HAZARDOUS_GOODS = 'shippedHazardousGoods',
    CHARGING_POWER = 'chargingPower',
}

export interface VehicleAttributesUnit {
    readonly [propName: string]: string;
}

export const VEHCILE_ATTRIBUTES_UNITS: VehicleAttributesUnit = {
    [HEREVehicleAttibuteNames.HEIGHT]: Unit.meters,
    [HEREVehicleAttibuteNames.LENGTH]: Unit.meters,
    [HEREVehicleAttibuteNames.WIDTH]: Unit.meters,
    [HEREVehicleAttibuteNames.PAYLOAD]: Unit.weight,
    [HEREVehicleAttibuteNames.TOTAL_WEIGHT]: Unit.weight,
    [HEREVehicleAttibuteNames.EMPTY_WEIGHT]: Unit.weight,
    [HEREVehicleAttibuteNames.CARGO_WEIGHT]: Unit.weight,
    [HEREVehicleAttibuteNames.OUTDOOR_TEMPERATURE]: Unit.temperature,
    [HEREVehicleAttibuteNames.LIMITED_WEIGHT]: Unit.tons,
    [HEREVehicleAttibuteNames.WEIGHT_PER_AXLE]: Unit.weight,
    [HEREVehicleAttibuteNames.WEIGHT_PER_AXLE_GROUP]: Unit.weight,
    [HEREVehicleAttibuteNames.CHARGING_POWER]: Unit.KWH,
};

export interface VehicleAttributes {
    [HEREVehicleAttibuteNames.TOLL_VEHICLE_TYPE]?: TollVehicleType;
    [HEREVehicleAttibuteNames.HEIGHT]?: number;
    [HEREVehicleAttibuteNames.LENGTH]?: number;
    [HEREVehicleAttibuteNames.WIDTH]?: number;
    [HEREVehicleAttibuteNames.LIMITED_WEIGHT]?: number;
    [HEREVehicleAttibuteNames.WEIGHT_PER_AXLE]?: number;
    [HEREVehicleAttibuteNames.VEHCILE_NUMBER_AXLES]?: number;
    [HEREVehicleAttibuteNames.TRAILER_NUMBER_AXLES]?: number;
    [HEREVehicleAttibuteNames.EMISSION_TYPE]?: EmissionClass;
    [HEREVehicleAttibuteNames.SHIPPED_HAZARDOUS_GOODS]?: HazardClass;

    readonly [propName: string]: number | string | undefined;
}

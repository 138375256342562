import { coordinatesToCoords } from '../../../common/utils';
import { store } from '../../../configuration/setup/store';
import { trace } from '../../../configuration/setup/trace';
import { fetchRoutes } from '../../../features/fetchData/fetchRoutes';
import { selectCurrentStep } from '../../app/appSelectors';
import { NavigationStep } from '../../app/appSlice';
import { ChargingStation } from '../../facade/facadeApi';
import { Waypoint, WaypointType, waypointAdded, waypointChanged } from '../../search/searchSlice';

export const ADD_ROUTE_STATION_EVENT = 'EVENT_ADD_ROUTE_STATION';

export const addStationWaypointHandler = async (stationData: ChargingStation) => {
    const searchSliceState = store.getState().search;
    const waypointsList = searchSliceState.waypoints;
    const waypointsOderList = searchSliceState.waypointsOrder;
    const copyLastWaypoint = waypointsList.find((wp) => wp.id === waypointsOderList.at(-1));
    const isWaypointDuplicated = waypointsList.some((wp) => wp.address === stationData.address);

    if (!isWaypointDuplicated) {
        store.dispatch(waypointAdded(-1));
        store.dispatch(
            waypointChanged({
                id: store.getState().search.waypointsOrder.at(-2),
                address: stationData.address,
                coordinates: coordinatesToCoords(stationData.location),
                type: WaypointType.CHARGING_STATION,
                station_id: stationData.id,
            } as Waypoint)
        );

        if (copyLastWaypoint) {
            store.dispatch(
                waypointChanged({
                    id: store.getState().search.waypointsOrder.at(-1),
                    address: copyLastWaypoint.address,
                    coordinates: copyLastWaypoint.coordinates,
                    type: copyLastWaypoint.type,
                } as Waypoint)
            );
        }

        const currentStep = selectCurrentStep(store.getState());
        if (currentStep === NavigationStep.StopsStep) {
            try {
                await store.dispatch(fetchRoutes);
            } catch (error: any) {
                trace.error('could not fetch range', error);
                throw error;
            }
        }
    }
};

import Fade from '@rio-cloud/rio-uikit/Fade';
import gt from 'lodash/fp/gt';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_ROUTE } from '../../common/constants';
import { useAppSelector } from '../../configuration/setup/hooks';
import { SectionsContainer } from '../../containers/SectionsContainer/SectionsContainer';
import { TourAlternatives } from '../../containers/TourAlternatives/TourAlternatives';
import { TourSummary } from '../../containers/TourSummary/TourSummary';
import { selectShowSummary } from '../../store/app/appSelectors';
import { selectSelectedRoute, selectSuggestedRoutes } from '../../store/route/routeSelectors';
import { lazy } from 'react';
import Button from '@rio-cloud/rio-uikit/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import { selectOrderedWaypoints } from '../../store/search/searchSelectors';
import first from 'lodash/fp/first';
import last from 'lodash/fp/last';
import { showMapNotification } from '../../store/widget/actions/showMapNotification';
import { coordsToCoordinates } from '../../common/utils';
import { isUndefined } from 'lodash/fp';

const TripSummaryPDF = lazy(() =>
    import('../../components/DownloadPDF/DownloadPDF').then((module) => ({ default: module.DownloadPDF }))
);

export const RouteSummaryPage = () => {
    const intl = useIntl();
    const navigate = useNavigate();

    const showContent = useAppSelector(selectShowSummary);

    const suggestedRoutes = useAppSelector(selectSuggestedRoutes);
    const trip = useAppSelector(selectSuggestedRoutes);
    const selectedRoute = useAppSelector(selectSelectedRoute);
    const selectedRouteData = trip?.routes[selectedRoute!];

    const waypoints = useAppSelector(selectOrderedWaypoints);
    const departureWaypoint = first(waypoints);
    const arrivalWaypoint = last(waypoints);

    const sendToVehicleHandler = async () => {
        if (isUndefined(departureWaypoint?.coordinates) || isUndefined(arrivalWaypoint?.coordinates)) {
            return;
        }
        try {
            const response = await fetch('https://2d42flfxs4.execute-api.eu-west-1.amazonaws.com/prod/create', {
                method: 'POST',
                body: JSON.stringify({
                    origin: coordsToCoordinates(departureWaypoint?.coordinates),
                    destination: coordsToCoordinates(arrivalWaypoint?.coordinates),
                    device_id: '1ebff057-3699-4eb1-a47b-5e21054493cf',
                }),
            });

            if (response.ok) {
                showMapNotification(
                    'success',
                    intl.formatMessage({ id: 'intl.msg:smartRoute.sendTourToVehicle.successMessage' }),
                    intl.formatMessage({ id: 'intl.msg:smartRoute.toast.title' })
                );
            } else {
                throw new Error();
            }
        } catch (error) {
            showMapNotification(
                'error',
                intl.formatMessage({ id: 'intl.msg:smartRoute.sendTourToVehicle.errorMessage' }),
                intl.formatMessage({ id: 'intl.msg:smartRoute.toast.title' })
            );
        }
    };

    return (
        <Fade
            animationStyle={Fade.PAGE_BACK}
            show={showContent}
            onExitComplete={() => navigate(DEFAULT_ROUTE, { replace: false })}
        >
            <div className="display-flex flex-column" data-testid="route-summary-layout">
                {trip && (
                    <div className="padding-15 display-flex flex-column gap-25" data-testid="route-summary-tabs">
                        {gt(suggestedRoutes!.routes.length, 1) && <TourAlternatives routes={trip.routes} />}

                        <TourSummary trip={trip} selectedRoute={selectedRoute!} />

                        {selectedRouteData && <SectionsContainer route={selectedRouteData} />}
                    </div>
                )}
            </div>
            <div className="display-flex padding-15 justify-content-between" data-testid="route-widget-download">
                <TripSummaryPDF />
                <div>
                    <Button onClick={sendToVehicleHandler} bsStyle="primary">
                        <span className="rioglyph rioglyph-send" aria-hidden="true" />
                        <FormattedMessage id="intl.msg:smartRoute.pdf.sendButton" />
                    </Button>
                </div>
            </div>
        </Fade>
    );
};

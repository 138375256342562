import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { STORE_BASE_PATH } from '../../common/constants';
import { Coords } from '../../common/types';

export interface GeoLocation {
    coords?: Coords;
    name?: string;
    address?: string;
}

export interface LocationsState {
    locations: GeoLocation[];
}

const initialState: LocationsState = {
    locations: [],
};

interface LocationsPayload {
    index: number;
    geolocation: GeoLocation;
}

export const locationsSlice = createSlice({
    name: STORE_BASE_PATH + 'locations',
    initialState,
    reducers: {
        addLocation: (state, action: PayloadAction<LocationsPayload>) => {
            const array = state.locations.slice();
            array.splice(action.payload.index, 1, action.payload.geolocation);
            state.locations = array;
        },
        removeLocation: (state, action: PayloadAction<number>) => {
            const array = state.locations.filter((location, index) => index !== action.payload);
            state.locations = array;
        },
    },
});

export const { addLocation, removeLocation } = locationsSlice.actions;

export default locationsSlice.reducer;

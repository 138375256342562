import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { STORE_BASE_PATH } from '../../common/constants';
import { Geofence } from '../../features/fetchData/mappers/mapGeofence';
import { Poi } from '../../features/fetchData/mappers/mapPoi';

export interface HereSettings {
    apikey: string;
}

export interface DataState {
    isGeoBooked: boolean;
    hereSettings?: HereSettings;
    fetchHereSettingsFailed?: boolean;
    fetchPoisFailed?: boolean;
    fetchGeofencesFailed?: boolean;
    pois: Poi[];
    geofences: Geofence[];
}

const initialState: DataState = {
    isGeoBooked: true,
    fetchPoisFailed: undefined,
    fetchGeofencesFailed: undefined,
    pois: [],
    geofences: [],
};

export const dataSlice = createSlice({
    name: STORE_BASE_PATH + 'data',
    initialState,
    reducers: {
        geoBookedChanged: (state: DataState, action: PayloadAction<any>) => {
            state.isGeoBooked = action.payload;
        },
        hereSettingsChanged: (state: DataState, action: PayloadAction<any>) => {
            state.hereSettings = action.payload;
        },
        fetchHereSettingsFailed: (state: DataState) => {
            state.fetchHereSettingsFailed = true;
        },
        fetchPoisSuccess: (state: DataState, action: PayloadAction<Poi[]>) => {
            state.pois = action.payload;
            state.fetchPoisFailed = false;
        },
        fetchPoisFailed: (state: DataState) => {
            state.fetchPoisFailed = true;
        },
        fetchGeofencesSuccess: (state: DataState, action: PayloadAction<any>) => {
            state.geofences = action.payload;
            state.fetchGeofencesFailed = false;
        },
        fetchGeofencesFailed: (state: DataState) => {
            state.fetchGeofencesFailed = true;
        },
    },
});

export const {
    geoBookedChanged,
    hereSettingsChanged,
    fetchHereSettingsFailed,
    fetchPoisSuccess,
    fetchPoisFailed,
    fetchGeofencesSuccess,
    fetchGeofencesFailed,
} = dataSlice.actions;

export default dataSlice.reducer;

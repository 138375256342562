import { createSelector } from 'reselect';
import { RootState } from '../../configuration/setup/store';

export const selectCharging = (state: RootState) => state.charging;
export const selectStateOfCharge = createSelector(selectCharging, (state) => state.stateOfCharge);
export const selectChargingPower = createSelector(selectCharging, (state) => state.chargingPower);
export const selectChargingOptionsGrading = createSelector(selectCharging, (state) => state.chargingOptions.grading);
export const selectChargingOptionsPlugType = createSelector(selectCharging, (state) => state.chargingOptions.plugType);
export const selectChargingOptionsOperator = createSelector(selectCharging, (state) => state.chargingOptions.operator);
export const selectChargingOptionsPayment = createSelector(selectCharging, (state) => state.chargingOptions.payment);

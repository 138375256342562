import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import SmoothScrollbars from '@rio-cloud/rio-uikit/components/smoothScrollbars/SmoothScrollbars';
import NotificationsContainer from '@rio-cloud/rio-uikit/NotificationsContainer';
import { IntlProvider } from 'react-intl';
import { Outlet } from 'react-router-dom';
import { Upselling } from '../../components/Upselling/Upselling';
import { config } from '../../configuration/config';
import { useAppDispatch, useAppSelector } from '../../configuration/setup/hooks';
import { fetchHereSettings } from '../../features/fetchData/fetchHereSettings';
import { useAccountSubscription } from '../../hooks/AccountSubscriptionHooks';
import { useEffectOnce } from '../../hooks/hooks';
import { useWidgetListener } from '../../hooks/widgetHooks';
import { DefaultRedirect } from '../../routes/DefaultRedirect';
import { selectDisplayMessages, selectLocale } from '../../store/lang/langSelectors';
import { sendMessage } from '../../store/widget/widgetServices';
import { widgetActions } from '../../store/widget/widgetSlice';
import { BottomNavigation } from '../BottomNavigation/BottomNavigation';
import { TopNavigation } from '../TopNavigation/TopNavigation';
import { SubmitForm } from '../../components/SubmitForm/SubmitForm';
import { selectShowSettings } from '../../store/app/appSelectors';

export const AppLayout = () => {
    const dispatch = useAppDispatch();
    const userLocale = useAppSelector(selectLocale);
    const displayMessages = useAppSelector(selectDisplayMessages);
    const isSettingsPage = useAppSelector(selectShowSettings);

    useWidgetListener();

    useEffectOnce(() => {
        sendMessage(widgetActions.widgetLoaded());
        dispatch(fetchHereSettings);
    });

    const { subscribed, isLoading } = useAccountSubscription();

    return (
        <IntlProvider
            locale={userLocale}
            defaultLocale={config.login.defaultLocale}
            key={userLocale}
            messages={displayMessages}
        >
            <ApplicationLayout data-testid="app-layout">
                <NotificationsContainer />
                {!isLoading && subscribed && (
                    <div className="display-flex flex-column height-100vh" data-testid="app-layout-content">
                        <TopNavigation />
                        <SmoothScrollbars>
                            <Outlet />
                        </SmoothScrollbars>
                        {isSettingsPage && (
                            <div data-testid="submit-container" className="margin-x-15">
                                <SubmitForm />
                            </div>
                        )}
                        <BottomNavigation />
                    </div>
                )}
                {!isLoading && !subscribed && <Upselling />}
                <DefaultRedirect />
            </ApplicationLayout>
        </IntlProvider>
    );
};

import isNil from 'lodash/fp/isNil';
import { store } from '../../../configuration/setup/store';
import { selectWidget } from '../widgetSelectors';
import { updateWidgetVisibility } from '../widgetSlice';

export const VISIBLE_WIDGETS_EVENT = 'EVENT_SET_VISIBLE_WIDGETS';

type VisibleWidgetsType = string[];

export const visibleWidgetsHandler = (visibleWidgets: VisibleWidgetsType) => {
    const widgetState = selectWidget(store.getState());

    if (isNil(widgetState.widgetId)) {
        return;
    }

    // TODO - Change string literal to widgetState.widgetId when livemonitor fixes EVENT_SET_WIDGET_ID
    const isVisible = visibleWidgets.includes('SmartRoutePlanningSettingsWidget');
    if (isVisible === true && widgetState.isVisible === false) {
        store.dispatch(updateWidgetVisibility(true));
    } else if (isVisible === false && widgetState.isVisible === true) {
        store.dispatch(updateWidgetVisibility(false));
    }
};

import { VehicleType2 } from '../facade/facadeApi';
import { EmissionClass, HazardClass, PlugType } from './vehicleProfileTypes';

export const defaultVehicleProfile = {
    profileType: '02bb3f45-0b1d-4a11-8f20-05fde3b1b966',
    length: 0,
    height: 0,
    width: 0,
    totalWeight: 0,
    vehicleAxles: 1,
    trailerAxles: 0,
    emissionClassification: EmissionClass.EURO_IV,
    hazardClassification: HazardClass.NONE,
    payloadWeight: 0,
    averageOutdoorTemperature: 20,
    minSoC: 10,
    maxSoC: 80,
    originSoC: 80,
    destinationSoC: 10,
    plugType: PlugType.Type1,
    costPerKm: 0.0,
    costPerKWh: 0.0,
    weightPerAxle: 1000,
    truckType: VehicleType2.StraightTruck,
    currency: 'EUR',
};

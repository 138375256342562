import { config } from '../../configuration/config';
import { fetchHereSettingsFailed, hereSettingsChanged } from '../../store/data/dataSlice';

export const API_CONF_ENDPOINT = '/configurations';

export const fetchHereSettings = (dispatch: Function) => {
    try {
        const hereSettings = { apikey: `${config.backend.MAP_SERVICE_API_KEY}` };
        dispatch(hereSettingsChanged(hereSettings));
    } catch (error: any) {
        dispatch(fetchHereSettingsFailed(error));
    }
};

import { MapContext } from '../../../common/types';
import { store } from '../../../configuration/setup/store';
import { trace } from '../../../configuration/setup/trace';
import { updateMapContext } from '../widgetSlice';

export const SET_MAP_CONTEXT_EVENT = 'EVENT_SET_MAP_CONTEXT';

export type MapContextType = (typeof MapContext)[keyof typeof MapContext];

export const setMapContextHandler = (data: MapContextType) => {
    trace.debug('Set map context:', data);
    store.dispatch(updateMapContext(data));
};

import { isEmpty } from 'lodash';
import { assign, isNil } from 'lodash/fp';
import { FormattedMessage } from 'react-intl';
import { useAppSelector } from '../../configuration/setup/hooks';
import { RouteDetails } from '../../features/fetchData/mappers/mapRoutes';
import {
    AdditionalStopSection,
    ArrivalSection,
    ChargingStationSection,
    DepartureSection,
    DrivingSection,
    SectionType,
} from '../../store/facade/facadeApi';
import { selectOrderedWaypoints } from '../../store/search/searchSelectors';
import { ArrivalPanel } from './Sections/ArrivalPanel';
import { ChargingPanel } from './Sections/ChargingPanel';
import { DeparturePanel } from './Sections/DeparturePanel';
import { DrivingPanel } from './Sections/DrivingPanel';
import { StopoverPanel } from './Sections/StopoverPanel';

export type CurrentStatus = {
    currentDuration: number;
    currentDistance: number;
};

export type SectionsContainerProps = {
    route: RouteDetails;
};

export const SectionsContainer = ({ route }: SectionsContainerProps) => {
    const waypoints = useAppSelector(selectOrderedWaypoints);

    const renderTimeline = () => {
        let currentDuration = 0;
        let currentDistance = 0;
        let currentStop = 1;

        return route.sections.map((section, index) => {
            switch (section.sectionType) {
                case SectionType.Departure: {
                    const departure = section.info as DepartureSection;
                    return <DeparturePanel departure={departure} key={index} />;
                }
                case SectionType.Driving: {
                    const driving = section.info as DrivingSection;
                    currentDistance += driving.distance;
                    currentDuration += driving.estimated_duration;
                    const isFirstDriving = index === 1;
                    return (
                        <DrivingPanel
                            driving={driving}
                            currentDistance={currentDistance}
                            currentDuration={currentDuration}
                            isFirstDriving={isFirstDriving}
                            key={index}
                        />
                    );
                }
                case SectionType.ChargingStation: {
                    const charging = section.info as ChargingStationSection;
                    currentDuration += charging.duration!;
                    currentStop++;

                    return (
                        <ChargingPanel
                            charging={charging}
                            currentDistance={currentDistance}
                            currentDuration={currentDuration}
                            key={index}
                        />
                    );
                }
                case SectionType.AdditionalStop: {
                    let restStop = section.info as AdditionalStopSection;
                    if (isNil(restStop.address) || isEmpty(restStop.address)) {
                        restStop = assign(restStop, { address: waypoints[currentStop]?.address ?? '' });
                    }
                    currentDuration += restStop.duration;
                    currentStop++;

                    return (
                        <StopoverPanel
                            stopover={restStop}
                            currentDistance={currentDistance}
                            currentDuration={currentDuration}
                            key={index}
                        />
                    );
                }
                case SectionType.Arrival: {
                    const arrival = section.info as ArrivalSection;
                    return <ArrivalPanel arrival={arrival} key={index} />;
                }
            }
        });
    };

    return (
        <div>
            <div className="text-medium text-size-18 margin-bottom-10">
                <FormattedMessage id="intl-msg:smartRoute.tour.details" />
            </div>
            <div className="timeline" data-testid="tour-timeline">
                {renderTimeline()}
            </div>
        </div>
    );
};

import { Unit } from '../../common/constants';

interface TourSummaryPanelHeaderProps {
    headerDuration?: string;
    headerDistance?: string;
}

export const TourSummaryPanelHeader = ({ headerDuration, headerDistance }: TourSummaryPanelHeaderProps) => {
    return (
        <div className="display-flex column-gap-10 justify-content-between text-medium" data-testid="tour-top">
            <span className="text-size-20 rioglyph rioglyph-start line-height-20 text-color-gray" />
            <span>
                <span>{headerDuration}</span>
                <span className="margin-left-5 margin-right-5">/</span>
                <span>
                    {headerDistance}
                    {Unit.km}
                </span>
            </span>
            <span className="text-size-20 rioglyph rioglyph-finish line-height-20 text-color-gray" />
        </div>
    );
};

import { VehicleType2 } from '../../../src/store/facade/facadeApi';

export const generateVehicleProfileDetailsMock1 = () => ({
    id: '02bb3f45-0b1d-4a11-8f20-05fde3b1b966',
    profileName: 'man-etgs-4x2-chassis-box-solo',
    vehicleType: VehicleType2.StraightTruck,
    engineType: 'BEV',
    length: 9.5,
    width: 2.55,
    height: 3.6,
    emptyWeightVehicle: [
        {
            packs: 3,
            weight: 8.0,
        },
        {
            packs: 4,
            weight: 8.7,
        },
        {
            packs: 5,
            weight: 9.5,
        },
        {
            packs: 6,
            weight: 10.2,
        },
    ],
    emptyWeightFreightTrain: [
        {
            packs: 3,
            weight: 8.0,
        },
        {
            packs: 4,
            weight: 8.7,
        },
        {
            packs: 5,
            weight: 9.5,
        },
        {
            packs: 6,
            weight: 10.2,
        },
    ],
    maxFreightTrainWeight: 20,
    maxCargoWeight: [
        {
            packs: 3,
            weight: 12.0,
        },
        {
            packs: 4,
            weight: 11.3,
        },
        {
            packs: 5,
            weight: 10.5,
        },
        {
            packs: 6,
            weight: 9.8,
        },
    ],
    maxWeightPerAxle: 11.5,
    axlesVehicle: 2,
    numberAttachedTrailers: 0,
    defaultInstalledBatteryPackCount: 3,
    batteryCapacityPerPack: 80,
    plugTypes: ['CCS'],
});

export const generateVehicleProfileDetailsMock2 = () => ({
    id: '9fe9a4c7-1ea1-4515-931f-13984a27f9af',
    profileName: 'man-etgs-6x2-chassis-cooled-box',
    vehicleType: VehicleType2.StraightTruck,
    engineType: 'BEV',
    length: 18.75,
    width: 2.55,
    height: 3.8,
    emptyWeightVehicle: [
        {
            packs: 4,
            weight: 9.8,
        },
        {
            packs: 5,
            weight: 10.6,
        },
        {
            packs: 6,
            weight: 11.4,
        },
    ],
    emptyWeightFreightTrain: [
        {
            packs: 4,
            weight: 14.63,
        },
        {
            packs: 5,
            weight: 15.43,
        },
        {
            packs: 6,
            weight: 16.23,
        },
    ],
    maxFreightTrainWeight: 42,
    maxCargoWeight: [
        {
            packs: 4,
            weight: 27.37,
        },
        {
            packs: 5,
            weight: 26.57,
        },
        {
            packs: 6,
            weight: 25.77,
        },
    ],
    maxWeightPerAxle: 11.5,
    maxWeightPerAxleGroup: 19,
    axlesVehicle: 3,
    axlesTrailer: 2,
    numberAttachedTrailers: 1,
    defaultInstalledBatteryPackCount: 6,
    batteryCapacityPerPack: 80,
    plugTypes: ['CCS'],
});

export const generateVehicleProfileDetailsMock3 = () => ({
    id: 'a0e1890c-69cf-4060-8ba0-c1bc4f966a8e',
    profileName: 'man-etgx-4x2-tractor-semitrailer',
    vehicleType: 'TRACTOR',
    engineType: 'BEV',
    length: 16.5,
    width: 2.55,
    height: 4.0,
    emptyWeightVehicle: [
        {
            packs: 4,
            weight: 8.8,
        },
        {
            packs: 5,
            weight: 9.5,
        },
        {
            packs: 6,
            weight: 10.2,
        },
    ],
    emptyWeightFreightTrain: [
        {
            packs: 4,
            weight: 14.74,
        },
        {
            packs: 5,
            weight: 15.44,
        },
        {
            packs: 6,
            weight: 16.14,
        },
    ],
    maxFreightTrainWeight: 42,
    maxCargoWeight: [
        {
            packs: 4,
            weight: 27.26,
        },
        {
            packs: 5,
            weight: 26.56,
        },
        {
            packs: 6,
            weight: 25.86,
        },
    ],
    maxWeightPerAxle: 11.5,
    axlesVehicle: 2,
    axlesTrailer: 3,
    numberAttachedTrailers: 1,
    defaultInstalledBatteryPackCount: 6,
    batteryCapacityPerPack: 80,
    plugTypes: ['CCS', 'MCS'],
});

export const generateVehicleProfileDetailsMock4 = () => ({
    id: '92854298-8762-4e9e-9d21-e51889ffa08f',
    profileName: 'man-etgx-4x2-ultra-tractor-semitrailer',
    vehicleType: 'TRACTOR',
    engineType: 'BEV',
    length: 16.5,
    width: 2.55,
    height: 4.0,
    emptyWeightVehicle: [
        {
            packs: 4,
            weight: 8.9,
        },
        {
            packs: 5,
            weight: 9.6,
        },
        {
            packs: 6,
            weight: 10.3,
        },
    ],
    emptyWeightFreightTrain: [
        {
            packs: 4,
            weight: 15.25,
        },
        {
            packs: 5,
            weight: 15.9,
        },
        {
            packs: 6,
            weight: 16.9,
        },
    ],
    maxFreightTrainWeight: 42,
    maxCargoWeight: [
        {
            packs: 4,
            weight: 26.75,
        },
        {
            packs: 5,
            weight: 26.1,
        },
        {
            packs: 6,
            weight: 25.1,
        },
    ],
    maxWeightPerAxle: 11.5,
    axlesVehicle: 2,
    axlesTrailer: 3,
    numberAttachedTrailers: 1,
    defaultInstalledBatteryPackCount: 6,
    batteryCapacityPerPack: 80,
    plugTypes: ['CCS', 'MCS'],
});

export const generateVehicleProfileDetailsMock5 = () => ({
    id: '8eb1bf34-9c01-4148-b325-5bd3e562d417',
    profileName: 'man-etgx-6x2-chassis-swap-body',
    vehicleType: VehicleType2.StraightTruck,
    engineType: 'BEV',
    length: 18.75,
    width: 2.55,
    height: 4.0,
    emptyWeightVehicle: [
        {
            packs: 4,
            weight: 10.3,
        },
        {
            packs: 5,
            weight: 11.1,
        },
        {
            packs: 6,
            weight: 11.9,
        },
    ],
    emptyWeightFreightTrain: [
        {
            packs: 4,
            weight: 19.5,
        },
        {
            packs: 5,
            weight: 20.3,
        },
        {
            packs: 6,
            weight: 21.1,
        },
    ],
    maxFreightTrainWeight: 42,
    maxCargoWeight: [
        {
            packs: 4,
            weight: 22.5,
        },
        {
            packs: 5,
            weight: 21.7,
        },
        {
            packs: 6,
            weight: 20.9,
        },
    ],
    maxWeightPerAxle: 11.5,
    maxWeightPerAxleGroup: 19,
    axlesVehicle: 3,
    axlesTrailer: 2,
    numberAttachedTrailers: 1,
    defaultInstalledBatteryPackCount: 6,
    batteryCapacityPerPack: 80,
    plugTypes: ['CCS', 'MCS'],
});

export const generateVehicleProfileDetailsMock6 = () => ({
    id: '46a5cf52-e375-4c8b-96c7-b5b0cab77500',
    profileName: 'man-etgx-6x2-chassis-roll-off-tipper',
    vehicleType: VehicleType2.StraightTruck,
    engineType: 'BEV',
    length: 10.0,
    width: 2.55,
    height: 4.0,
    emptyWeightVehicle: [
        {
            packs: 4,
            weight: 9.8,
        },
        {
            packs: 5,
            weight: 10.6,
        },
        {
            packs: 6,
            weight: 11.4,
        },
    ],
    emptyWeightFreightTrain: [
        {
            packs: 4,
            weight: 12.7,
        },
        {
            packs: 5,
            weight: 13.47,
        },
        {
            packs: 6,
            weight: 14.27,
        },
    ],
    maxFreightTrainWeight: 28,
    maxCargoWeight: [
        {
            packs: 4,
            weight: 15.3,
        },
        {
            packs: 5,
            weight: 14.53,
        },
        {
            packs: 6,
            weight: 13.73,
        },
    ],
    maxWeightPerAxle: 11.5,
    maxWeightPerAxleGroup: 19,
    axlesVehicle: 3,
    numberAttachedTrailers: 0,
    defaultInstalledBatteryPackCount: 4,
    batteryCapacityPerPack: 80,
    plugTypes: ['CCS'],
});

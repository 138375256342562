import { HttpResponse, http } from 'msw';
import { API, BASE_URL } from '../../../src/common/constants';
import {
    generateVehicleProfileDetailsMock1,
    generateVehicleProfileDetailsMock2,
    generateVehicleProfileDetailsMock3,
    generateVehicleProfileDetailsMock4,
    generateVehicleProfileDetailsMock5,
    generateVehicleProfileDetailsMock6,
} from '../data/VehicleProfileDetailsMock';

const URL = `${BASE_URL}${API.endpoints.vehicleProfiles}/:id`;

export default http.get(URL, async ({ params }) => {
    const id = params.id;
    let profileDetails;

    switch (id) {
        case '02bb3f45-0b1d-4a11-8f20-05fde3b1b966': {
            profileDetails = generateVehicleProfileDetailsMock1();
            break;
        }
        case '9fe9a4c7-1ea1-4515-931f-13984a27f9af': {
            profileDetails = generateVehicleProfileDetailsMock2();
            break;
        }
        case 'a0e1890c-69cf-4060-8ba0-c1bc4f966a8e': {
            profileDetails = generateVehicleProfileDetailsMock3();
            break;
        }
        case '92854298-8762-4e9e-9d21-e51889ffa08f': {
            profileDetails = generateVehicleProfileDetailsMock4();
            break;
        }
        case '8eb1bf34-9c01-4148-b325-5bd3e562d417': {
            profileDetails = generateVehicleProfileDetailsMock5();
            break;
        }
        case '46a5cf52-e375-4c8b-96c7-b5b0cab77500': {
            profileDetails = generateVehicleProfileDetailsMock6();
            break;
        }
        default:
            profileDetails = generateVehicleProfileDetailsMock1();
    }

    return HttpResponse.json(profileDetails);
});

import { WINDOW_BOUNDING_BOX_DEGREES } from '../../../common/constants';
import { Coords } from '../../../common/types';
import { sendMessage } from '../widgetServices';
import { widgetActions } from '../widgetSlice';

export type BoundingBoxType = {
    bbox: {
        topLeft: Coords;
        bottomRight: Coords;
    };
};

export const changeBoundingBox = (points: Coords[]) => {
    const latitudes = points.map((coordinate) => coordinate.lat);
    const longitudes = points.map((coordinate) => coordinate.lng);

    sendMessage(
        widgetActions.setBoundingBox({
            bbox: {
                topLeft: {
                    lat: Math.max(...latitudes) + WINDOW_BOUNDING_BOX_DEGREES,
                    lng: Math.min(...longitudes) - WINDOW_BOUNDING_BOX_DEGREES,
                },
                bottomRight: {
                    lat: Math.min(...latitudes) - WINDOW_BOUNDING_BOX_DEGREES,
                    lng: Math.max(...longitudes) + WINDOW_BOUNDING_BOX_DEGREES,
                },
            },
        })
    );
};

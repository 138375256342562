import { HttpResponse, http } from 'msw';
import { API, BASE_URL } from '../../../src/common/constants';
import { generateVehicleProfileListMock } from '../data/vehicleProfileListMock';

const URL = `${BASE_URL}${API.endpoints.vehicleProfiles}`;

export default http.get(URL, async () => {
    const profiles = generateVehicleProfileListMock();
    return HttpResponse.json(profiles);
});

import { http, HttpResponse } from 'msw';
import { SmartRouteWebApiService } from '../../../src/common/constants';
import { config } from '../../../src/configuration/config';

// eslint-disable-next-line max-len
const URL = `${config.backend.BACKEND_SERVICE}/${SmartRouteWebApiService.serviceName}/${SmartRouteWebApiService.apiVersion.latest}${SmartRouteWebApiService.endpoints.accountSubscription}`;

export default http.get(URL, () => {
    return HttpResponse.json({ subscribed: true });
});

export const activeSubscriptionResponse = http.get(URL, () => {
    return HttpResponse.json({ subscribed: true });
});

export const inactiveSubscriptionResponse = http.get(URL, () => {
    return HttpResponse.json({ subscribed: false });
});
